import React from "react";

interface TimePickerProps {
  timeList: string[];
  selectedTime: string;
  selectedDate: Date | undefined;
  onTimeSelect: (time: string) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({
  timeList,
  selectedTime,
  onTimeSelect,
  selectedDate,
}) => {
  return (
    <div className="timeList">
      {timeList.length > 0 ? <h4>Time</h4> : <></>}
      <div className="timeListScroll">
        {timeList.length > 0 ? (
          <ul>
            {timeList.map((time) => (
              <li
                key={time}
                onClick={() => onTimeSelect(time)}
                className={selectedTime === time ? "selected" : ""}
              >
                <span>{time.slice(0, 5)}</span>
                <i>
                  <img src="/images/tick.svg" alt="" />
                </i>
              </li>
            ))}
          </ul>
        ) : (
          <p>
            Our 🧞‍♂️ Genies are throwing a car-wash party!
            <br /> <br /> Pick another date, and we'll bring the sparkle
            straight to you 💦
          </p>
        )}
      </div>
    </div>
  );
};

export default TimePicker;
