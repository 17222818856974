import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { BrandInterface, ModelInterfact } from "./brand.slice";
import { RootState } from "../store";

export interface CustomerVehiclesAttributes {
    uuid: string;
    customer_id: string;
    vehicle_model_id: string;
    brand_id?: string;
    model?: ModelInterfact;
    brand?: BrandInterface;
    color?: string;
    year?: string;
    plate_no?: string;
    nick_name?: string;
    images?: Record<string, any>;
    status?: boolean;
    insurance?: string;
    car_registration?: string;
    battery_life?: string;
    tyre_pressure?: Array<any>;
    tyre_expiration?: Array<any>;
    oil_change?: Date;
    last_service_date?: Date;
    city_name?: string;
    country_name?: string;
    is_default?: boolean
}

export interface VehicleEngine {
    engine_id: string;
    "vehicle_engine.uuid": string;
    "vehicle_engine.name": string;
    "vehicle_engine.capacity": number;
    "vehicle_engine.grade": string;
    "vehicle_engine.status": boolean;
    "vehicle_engine.createdAt": string;
    "vehicle_engine.updatedAt": string;
}

interface Vehicle {
    vehicles: CustomerVehiclesAttributes[];
    selectedVehicle: CustomerVehiclesAttributes;
    defaultVehicle: CustomerVehiclesAttributes;
    engine: VehicleEngine[];
    vehicleError: Partial<CustomerVehiclesAttributes>;
}

const initialState: Vehicle = {
    vehicles: [],
    engine: [],
    selectedVehicle: {
        uuid: '',
        customer_id: '',
        vehicle_model_id: '',
        brand_id: '',
        color: '',
        year: '',
        plate_no: '',
        nick_name: '',
        status: true,
        insurance: '',
        car_registration: '',
        battery_life: '',
        city_name: '',
        country_name: '',
        is_default: false,
    },
    defaultVehicle: {
        uuid: '',
        customer_id: '',
        vehicle_model_id: '',
        brand_id: '',
        color: '#FFFFFF',
        year: '',
        plate_no: '',
        nick_name: '',
        status: true,
        insurance: '',
        car_registration: '',
        battery_life: '',
        city_name: '',
        country_name: '',
        is_default: false,
    },
    vehicleError: {
        brand_id: "",
        vehicle_model_id: "",
        year: "",
    },
}

const vehicleSlice = createSlice({
    name: "vehicle",
    initialState,
    reducers: {
        setVehicles: (state: Vehicle, action: PayloadAction<CustomerVehiclesAttributes[]>) => {
            state.vehicles = action.payload;
        },
        setSelectedVehicle: (state: Vehicle, action: PayloadAction<CustomerVehiclesAttributes>) => {
            state.selectedVehicle = action.payload;
        },
        setDefaultVehicle: (state: Vehicle, action: PayloadAction<CustomerVehiclesAttributes>) => {
            state.defaultVehicle = action.payload;
        },
        resetSelectedVehicle: (state: Vehicle) => {
            state.selectedVehicle = initialState.selectedVehicle;
        },
        resetDefaultVehicle: (state: Vehicle) => {
            state.defaultVehicle = initialState.defaultVehicle;
        },
        setEngine: (state: Vehicle, action: PayloadAction<VehicleEngine[]>) => {
            state.engine = action.payload;
        },
        updateVehicleField(
            state: Vehicle,
            action: PayloadAction<Partial<CustomerVehiclesAttributes>>
        ) {
            state.selectedVehicle = { ...state.selectedVehicle, ...action.payload };
        },
        setVehicleError: (state: Vehicle, action: PayloadAction<Partial<CustomerVehiclesAttributes>>) => {
            state.vehicleError = action.payload;
        },
        resetVehicle: (state: Vehicle) => {
            state.selectedVehicle = initialState.selectedVehicle;
            state.vehicles = initialState.vehicles
        },
    }
})

export const { setVehicles, setEngine, setDefaultVehicle, setSelectedVehicle, setVehicleError, updateVehicleField, resetDefaultVehicle, resetSelectedVehicle, resetVehicle } = vehicleSlice.actions;
export const vehiclesSelector = (state: RootState) => state.vehicle.vehicles;
export const selectedVehicleSelector = (state: RootState) => state.vehicle.selectedVehicle;
export const defaultVehicleSelector = (state: RootState) => state.vehicle.defaultVehicle;
export const engineSelector = (state: RootState) => state.vehicle.engine;
export const vehErrorSelector = (state: RootState) => state.vehicle.vehicleError;
export default vehicleSlice.reducer;