import React, { useState, useEffect } from "react";
import StepsHeader from "./StepsHeader";
// import StepsFooter from "./StepsFooter";
import { get } from "../../services/api.service";
import { Category } from "../../Types/category.interface";
import LoadingIndicator from "../loader/loader";

interface Options {
  name: string;
  uuid: string;
  image?: string;
}

interface Props {
  editData: any;
}

const StepWizard: React.FC<Props> = ({ editData }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [carTypes, setCarTypes] = useState<Options[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [brands, setBrands] = useState<Options[]>([]);
  const [models, setModels] = useState<Options[]>([]);
  const [years, setYears] = useState<Options[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [orderToEditDetail, setOrderToEditDetail] = useState(null);

  const totalSteps: number = 3; // Set the total number of steps here
  useEffect(() => {
    if (!editData?.forcedStep) return;
    setCurrentStep(editData?.forcedStep);
    setOrderToEditDetail(editData?.selectedOrders);
  }, [editData?.forcedStep]);

  const handleNext = (): void => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = (): void => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleLoader = (bool: boolean) => {
    // setIsLoading(bool);
  };

  const fetchCarTypes = async () => {
    try {
      const res = await get("vehicle/type");
      if (res) setCarTypes(res.result);
      //
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const fetchCarBrands = async () => {
    try {
      const res = await get("vehicle/brand");
      if (res) setBrands(res.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchCarModels = async () => {
    try {
      const res = await get("vehicle/model");
      if (res) setModels(res.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const generateLast100Years = () => {
    const today = new Date();
    const dates = [];
    for (
      let year = today.getFullYear() - 100;
      year <= today.getFullYear();
      year++
    ) {
      const date = new Date(year, today.getMonth(), today.getDate());
      dates.push({
        name: date.getFullYear().toString(),
        uuid: year.toString(),
      });
    }
    setYears(dates.reverse());
  };

  // useEffect(() => {
  //   fetchCarTypes();
  //   fetchCategories();
  //   fetchCarBrands();
  //   fetchCarModels();
  //   generateLast100Years();
  // }, []);

  return (
    <>
      <div className="cardMain">
        <StepsHeader currentStep={currentStep} totalSteps={totalSteps} />
        <div className="flex-1 cardInner">
          <div
            className="stepsMain"
            style={{ display: currentStep === 1 ? "flex" : "none" }}
          >
            {/* <CardServices handleLoader = {(bool: boolean)=> handleLoader(bool)} carTypes={carTypes} categories={categories}/> */}
          </div>
          <div
            className="stepsMain"
            style={{ display: currentStep === 2 ? "flex" : "none" }}
          >
            {/* <SecondStep brands={brands} models={models} years={years}/> */}
          </div>
          <div
            className="stepsMain"
            style={{ display: currentStep === 3 ? "flex" : "none" }}
          >
            {/* <EditLocationTime orderToEditDetails={orderToEditDetail} /> */}
          </div>
        </div>
        {/* <StepsFooter
          resetStep={() => setCurrentStep(1)}
          currentStep={currentStep}
          totalSteps={totalSteps}
          handleNext={handleNext}
          handleBack={handleBack}
        /> */}
      </div>
      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default StepWizard;
