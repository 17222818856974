import React, { useCallback, useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useLoadScript } from "@react-google-maps/api";
import Auth from "@aws-amplify/auth";
import { removeUser } from "../../features/auth.slice";
import { resetBooking } from "../../features/bookingSlice";
import { resetLocationState } from "../../features/location.slice";

const Topbar = () => {
  // const locationName = useSelector((state: RootState) => state.location.currentLocationName);
  //
  const locationName = localStorage.getItem(`currLocation`);
  const [currentLocation, setCurrentLocation] = useState("");
  const autocompleteService = useRef<any>();
  const dispatch = useDispatch();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY as string,
    libraries: ["places"],
  });

  useEffect(() => {
    if (isLoaded) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);
  useEffect(() => {
    // Get the current location when the component mounts
    if (isLoaded) {
      getCurrentLocation();
    }
  }, [isLoaded]);

  const getCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setCurrentLocation({ lat: latitude, lng: longitude });
          // dispatch(setLatLng({ latitude, longitude }));
          getLocationName(latitude, longitude);
          // dispatch(setCurrLocationName(getLocationName(latitude,longitude)));
          //
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  function getLocationName(latitude: number, longitude: number) {
    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (
        status === google.maps.GeocoderStatus.OK &&
        results &&
        results.length > 0
      ) {
        const place = results[0];
        const placeName = place.formatted_address;
        setCurrentLocation(placeName);
        localStorage.setItem("currLocation", placeName!);

        // dispatch(setLocationName(place.formatted_address));
        // dispatch(setCurrLocationName(place.formatted_address));
        return placeName;
      } else {
        console.error("Geocoder failed due to: ", status);
      }
    });
  }

  const handleLogout = () => {
    Auth.signOut().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(resetLocationState());
      dispatch(removeUser());
      dispatch(resetBooking());
    });
  };

  return (
    <>
      <div className="topbar">
        <Container>
          <Row>
            <Col lg={3} md={6}>
              <div className="address">
                <i>
                  <Icon icon="material-symbols:location-on-outline" />
                </i>{" "}
                <span>{currentLocation}</span>
              </div>
            </Col>
            <Col lg={9} md={6}>
              <div className="d-flex align-items-center justify-content-end gap-3 h-full">
                <div className="appLinks">
                  <p>Download our mobile app</p>
                  <Link to="https://apps.apple.com/us/app/jino-on-demand-car-services/id1462862177">
                    <img src="/images/appStore.svg" alt="" />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=com.os.jinocustomer">
                    <img src="/images/play.svg" alt="" />
                  </Link>
                </div>
                <div className="contactLinks d-xl-flex d-none ">
                  <a href="tel:+97142714716">
                    <i>
                      <Icon icon="iconamoon:phone-light" />
                    </i>{" "}
                    +971 42714716
                  </a>
                  <a href="mailto:support@jino.ae">
                    <i>
                      <Icon icon="ph:envelope-simple" />
                    </i>{" "}
                    support@jino.ae
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mobileContact d-xl-none block">
        <ul>
          <li>
            <a href="tel:+97142714716">
              <i>
                <Icon icon="iconamoon:phone-light" />
              </i>{" "}
              +971 42714716
            </a>
          </li>
          <li>
            {" "}
            <a href="mailto:support@jino.ae">
              <i>
                <Icon icon="ph:envelope-simple" />
              </i>{" "}
              support@jino.ae
            </a>
          </li>
          <li>
            <div className="address">
              <i>
                <Icon icon="material-symbols:location-on-outline" />
              </i>{" "}
              <span>{currentLocation}</span>
            </div>
          </li>
        </ul>
        <Link to="/" className="btn btnPrimary w-100" onClick={handleLogout}>
          <i>
            <Icon icon="iconoir:user-circle" />
          </i>{" "}
          Log out
        </Link>
        <div className="appLinks">
          <p>Download our mobile app</p>
          <div className="app">
            <Link to="https://play.google.com/store/apps/details?id=com.os.jinocustomer">
              <img src="/images/play.png" alt="" />
            </Link>
            <Link to="https://apps.apple.com/us/app/jino-on-demand-car-services/id1462862177">
              <img src="/images/app-store.png" alt="" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
