import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import SwiperCore, { Autoplay } from 'swiper';

// Install Swiper modules
SwiperCore.use([Autoplay]);

const ServicesSlider = () => {
  return (
    <>
      <Swiper
        className="mySwiper"
        speed={1000}
        autoplay={{
          delay:10000, 
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <div className='slider text-center'>
            <img src="./images/service1.svg" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slider text-center'>
            <img src="./images/service2.png" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slider text-center'>
            <img src="./images/service3.png" alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default ServicesSlider;
