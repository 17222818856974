import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import TagManager from "react-gtm-module";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import ContainerMain from "../../Components/layouts/main/ContainerMain";

const Thanks = () => {
  const location = useLocation();
  const [orderId, setOrderId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!location) return;
    const id: any = sessionStorage.getItem("setOrderId");
    if (id) {
      setOrderId(id);
    } else {
      setOrderId(location?.state?.state.bookingNumber);
    }
    toast.success("Order Booked Successfully");
    TagManager.dataLayer({
      dataLayer: {
        event: "Purchase",
      },
    });
  }, [location]);

  return (
    <ContainerMain>
      <section className="wrapperThanks">
        <Row className="g-0">
          <Col lg={6} className="order-lg-1 order-2">
            <div className="trackOrder">
              <img src="/images/devices.png" alt="" />
              <p>To track your order</p>
              <h4>Download our mobile app now</h4>
              <ul>
                {" "}
                <li>
                  <Link to="https://apps.apple.com/us/app/jino-on-demand-car-services/id1462862177">
                    <img
                      src="/images/appstore1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="https://play.google.com/store/apps/details?id=com.os.jinocustomer">
                    <img
                      src="/images/playstore1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </li>
              </ul>
              <Button
                className="btnSecondary"
                onClick={() => navigate("/booking-history")}
              >
                Continue to Booking History{" "}
                <i>
                  <Icon icon="la:long-arrow-alt-right" />
                </i>
              </Button>
            </div>
          </Col>
          <Col lg={6} className="order-lg-2 order-1">
            <div className="orderId">
              <img src="/images/thanks.png" alt="" className="img-fluid" />
              <h3>Thank you!</h3>
              <p>Your order has been confirmed.</p>
              <div className="id">Order ID# {orderId || ""}</div>
            </div>
          </Col>
        </Row>
      </section>
    </ContainerMain>
  );
};

export default Thanks;
