import React, { useState } from "react";
import { Dropdown, DropdownButton, FormControl } from "react-bootstrap";

interface CustomDropdownProps {
  options: any[];
  savedOptions?: any[];
  optionField: string;
  placeholder: string;
  selectedItem?: any;
  handleSelect: (option: any) => void;
  showImage?: boolean;
  error?: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  optionField,
  placeholder,
  selectedItem,
  handleSelect,
  showImage,
  error,
  savedOptions,
}) => {
  const handleSelectOption = (option: any) => {
    handleSelect(option);
  };

  const [search, setSearch] = useState("");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isSelected = (option: any) => selectedItem && option[optionField] === selectedItem[optionField];

  return (
    <DropdownButton
      className={`${error ? "error" : ""} selectIcon`}
      id="dropdown-basic-button"
      title={
        isDropdownOpen ? (
          <FormControl
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            autoFocus
            placeholder="Search..."
            className="w-full"
          />
        ) : (
          <span>
            {showImage && selectedItem && (
              <img
                src={selectedItem.image || "/images/jeep.svg"}
                alt={selectedItem.name}
                style={{ marginRight: "6px", width: "15px", height: "15px" }}
              />
            )}
            {selectedItem && selectedItem[optionField]
              ? selectedItem[optionField]
              : placeholder}
          </span>
        )
      }
      onToggle={(isOpen) => {
        setIsDropdownOpen(isOpen);
        setSearch("");
      }}
    >
      {savedOptions &&
        savedOptions?.length > 0 &&
        savedOptions
          .filter((option) =>
            option[optionField].toLowerCase().includes(search.toLowerCase())
          )
          .map((option, index) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={() => handleSelectOption(option)}
                className={isSelected(option) ? "selected" : ""}
              >
                {showImage && (
                  <img
                    src={option.image || "/images/jeep.svg"}
                    alt={option[optionField]}
                    style={{
                      marginRight: "6px",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                )}
                {option[optionField]}
              </Dropdown.Item>
            );
          })}
      {savedOptions &&
        savedOptions.filter((option) =>
          option[optionField].toLowerCase().includes(search.toLowerCase())
        )?.length > 0 && <hr />}
      {options
        .filter((option) =>
          option[optionField].toLowerCase().includes(search.toLowerCase())
        )
        .map((option, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleSelectOption(option)}
            className={isSelected(option) ? "selected" : ""}
          >
            {showImage && (
              <img
                src={option.image || "/images/jeep.svg"}
                alt={option[optionField]}
                style={{ marginRight: "6px", width: "15px", height: "15px" }}
              />
            )}
            {option[optionField]}
          </Dropdown.Item>
        ))}
    </DropdownButton>
  );
};

export default CustomDropdown;
