import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface City {
    city_name: string;
    uuid: string;
    country: {
        country_name: string;
        uuid: string;
        currency: string;
    };
};

export interface CustomerAddress {
    uuid?: string;
    customer_id: string;
    first_name: string;
    last_name: string;
    latitude: string;
    longitude: string;
    label: string;
    area: string;
    landmark: string | null;
    street_one: string | null;
    street_two: string | null;
    additional_address: string;
    city_id: string;
    city_name?: string;
    mobile_no: string;
    status: boolean;
    jsonDump: {
        label: string;
        unitType: string;
        unitNumber: string;
    };
    unitType?: string;
    createdAt: string;
    updatedAt: string;
    customer: any;
    city?: City;
    zone: string;
};

interface PolygonPoint {
    lat: number;
    lng: number;
};

interface Polygon {
    type: "polygon";
    zoom: number;
    center: PolygonPoint;
    latLng: PolygonPoint[];
};

interface BranchType {
    uuid: string;
    name: string;
    queue_threshold: null | any; // Change 'any' to the appropriate type if known
    mobile_no: null | string;
    email: null | string;
    city_id: string;
    polygon: Polygon;
    status: boolean;
    createdAt: string;
    updatedAt: string;
    schedule_id: string;
    city: City;
    level_type: "Branch";
};

interface Location {
    locations: CustomerAddress[]
    selectedLocation: CustomerAddress
    locationLevel: BranchType
    locationError: Partial<CustomerAddress>;
    cities: City[]
}

const initialState: Location = {
    locations: [],
    selectedLocation: {
        uuid: "",
        customer_id: "",
        first_name: "",
        last_name: "",
        latitude: "25.0786415",
        longitude: "55.1352524",
        label: "",
        area: "",
        zone: "",
        landmark: null,
        street_one: null,
        street_two: null,
        additional_address: "",
        city_id: "",
        city_name: "",
        mobile_no: "",
        status: true,
        jsonDump: {
            label: "",
            unitType: "",
            unitNumber: "",
        },
        unitType: "",
        createdAt: "",
        updatedAt: "",
        customer: null,
        city: {
            city_name: "",
            uuid: "",
            country: {
                country_name: "",
                uuid: "",
                currency: "",
            },
        }
    },
    locationLevel: {
        uuid: "",
        name: "",
        queue_threshold: null,
        mobile_no: null,
        email: null,
        city_id: "",
        polygon: {
            type: "polygon",
            zoom: 0,
            center: { lat: 0, lng: 0 },
            latLng: [],
        },
        status: true,
        createdAt: "",
        updatedAt: "",
        schedule_id: "",
        city: {
            city_name: "",
            uuid: "",
            country: {
                country_name: "",
                uuid: "",
                currency: "",
            },
        },
        level_type: "Branch",
    },
    cities: [],
    locationError: {
        label: '',
        street_one: '',
        unitType: ''
    }
}

const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        setAddresses: (state: Location, action: PayloadAction<CustomerAddress[]>) => {
            state.locations = action.payload;
        },
        setSelectedAddress: (state: Location, action: PayloadAction<CustomerAddress>) => {
            state.selectedLocation = action.payload;
        },
        updateAddressField(
            state: Location,
            action: PayloadAction<Partial<CustomerAddress>>
        ) {
            state.selectedLocation = { ...state.selectedLocation, ...action.payload };
        },
        setLocationError(state: Location, action: PayloadAction<any>) {
            state.locationError = action.payload;
        },
        resetSelectedAddress: (state: Location) => {
            state.selectedLocation = initialState.selectedLocation;
        },
        setLocationLevelID: (state: Location, action: PayloadAction<BranchType>) => {
            state.locationLevel = action.payload;
        },
        resetLocationLevelID: (state: Location) => {
            state.locationLevel = initialState.locationLevel;
        },
        setCitites: (state: Location, action: PayloadAction<City[]>) => {
            state.cities = action.payload
        },
        resetLocationState: (state: Location) => {
            state.locations = initialState.locations;
            state.locationLevel = initialState.locationLevel;
            state.selectedLocation = initialState.selectedLocation
        }
    }
})

export const { setAddresses, setSelectedAddress, updateAddressField, resetLocationState, setCitites, setLocationError, resetSelectedAddress, setLocationLevelID, resetLocationLevelID } = locationSlice.actions;
export const addressesSelector = (state: RootState) => state.location.locations
export const selectedAddressSelector = (state: RootState) => state.location.selectedLocation
export const locationErrorSelector = (state: RootState) => state.location.locationError
export const levelIDSelector = (state: RootState) => state.location.locationLevel
export const citiesSelector = (state: RootState) => state.location.cities
export default locationSlice.reducer;