import React from "react";
import StepCounter from "../widgets/StepCounter";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface StepsHeaderProps {
  currentStep: number;
  totalSteps: number;
}

const StepsHeader: React.FC<StepsHeaderProps> = ({
  currentStep,
  totalSteps,
}) => {
  const order = useSelector(
    (state: RootState) => state.order.bookings[state.order.bookings.length - 1]
  );

  const stepTitle = ["Choose Service", "Choose Vehicle", "Choose Location"];
  return (
    <>
      {currentStep !== 1 && (
        <div className="selectedService">
          <span>Selected Service</span>
          <div className="serviceTitle">
            {/* <h4><i><img src="https://assets.jino.ae/assets/others/235db8b3-c56e-4a53-bb31-cfb9ea85bda1.png" alt="" /></i> {order?.service?.service?.name}</h4> */}
            <h4>
              <i>
                <img
                  src={
                    order?.booking_details[0].service_config?.service?.category
                      ?.category_logo ?? "/images/carWash.svg"
                  }
                  alt=""
                />
              </i>{" "}
              {order?.booking_details[0]?.service_config?.service?.name}
            </h4>

            <div className="price">
              AED {order?.booking_details[0]?.service_config?.price}
            </div>
          </div>
        </div>
      )}
      <div className="cardHeader">
        <h3>{stepTitle[currentStep - 1]}</h3>
        {/* {currentStep === 2 && <h3>Choose Service</h3>} */}
        <StepCounter currentStep={currentStep} totalSteps={totalSteps} />
      </div>
    </>
  );
};

export default StepsHeader;
