import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import {
  proceedToPaymentCOD,
  paymentGatewayBooking,
  proceedToPayment,
} from "../../services/booking.service";
import { toast } from "react-hot-toast";
import RemoveModal from "./RemoveModal";
import TagManager from "react-gtm-module";
import {
  Booking,
  resetBooking,
  setSelectedBooking,
} from "../../features/bookingSlice";

// import axios from 'axios'

interface Order {
  selectedItem: Booking | null;
  onDelete: (orderId: any) => void;
  paymentMethod: string;
}

const CheckoutCta = ({ selectedItem, onDelete, paymentMethod }: Order) => {
  const user = useSelector((state: RootState) => state.user.user);
  const vat = useSelector((state: RootState) => state.setting.settings.vat);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let isDisabled: boolean = false;
  selectedItem ? (isDisabled = false) : (isDisabled = true);
  const COD = async () => {
    try {
      const requestBodyCOD = {
        comment: "",
        payment_type: "cash",
        uuid: selectedItem?.uuid,
        vat:
          (selectedItem?.booking_details[0]?.price || 0) *
          (parseInt(vat) / 100),
      };
      const res = await proceedToPaymentCOD(requestBodyCOD);
      if (res?.data) {
        dispatch(resetBooking());
        sessionStorage.setItem(
          "setOrderId",
          selectedItem?.id?.toString() || ""
        );
        dispatch(setSelectedBooking(null));
        navigate("/thanks", { state: { state: selectedItem } });
      }
      if (!res?.data) toast.error(res.error);
    } catch (error) {}
  };

  // useEffect(() => {
  //
  //   if (url) {
  //     window.location.href = url;
  //   }
  // }, [url]);

  const cardPayment = async () => {
    const min = 1;
    const max = 100;
    const rand = min + Math.random() * (max - min);

    let data = {
      booking_id: selectedItem?.uuid,
      method: "create",
      order: {
        cartid: rand,
        test: "0",
        amount:
          (selectedItem?.booking_details[0]?.service_config?.price || 0) +
          (selectedItem?.booking_details[0]?.service_config?.price || 0) *
            (parseInt(vat) / 100),
        currency: "AED",
        // selectedItem?.service?.related_value[0]?.city?.country?.currency?.currency, //currency,
        description: "Test Transaction",
        trantype: "sale",
      },
      customer: {
        ref: user?.uuid,
        email: user?.email,
        name: {
          forenames: user?.first_name,
          surname: user?.last_name,
        },
        address: {
          line1: selectedItem?.booking_details[0]?.customer_address?.area, //selectlocationName,
          // city: billingAddress?.city,
          country: "AE",
        },
        phone: user?.phone_number,
      },
      return: {
        authorised: process.env.REACT_APP_AUTHORIZED_URL,
        declined: process.env.REACT_APP_DECLINED_URL,
        cancelled: process.env.REACT_APP_CANCELLED_URL,
      },
    };
    const gatewayRes = await paymentGatewayBooking(data);
    sessionStorage.setItem("setOrderId", selectedItem?.id?.toString() || "");
    if (gatewayRes?.data?.order) {
      var resultGatewayResponse = gatewayRes?.data?.order;
      // setUrl(resultGatewayResponse?.order?.url);
      // resultGatewayResponse?.order?.url &&
      //   resultGatewayResponse?.order?.ref &&
      //   // proceedToPayment(gatewayRes?.data?.order?.url);
      //   window.location.replace(
      //     "https://www.fpga4student.com/2017/06/vhdl-code-for-arithmetic-logic-unit-alu.html",
      //   );
      // window.location.href = gatewayRes?.data?.order?.url;

      proceedToPay(resultGatewayResponse);
    }
  };

  const proceedToPay = async (payment: any) => {
    const payload = {
      uuid: selectedItem?.uuid,
      transaction_ref: payment.ref,
      vat:
        (selectedItem?.booking_details[0]?.price || 0) * (parseInt(vat) / 100),
      payment_type: "card",
      comment: "",
    };
    const res = await proceedToPayment(payload);
    if (res?.data) {
      dispatch(resetBooking());
      sessionStorage.setItem("setOrderId", selectedItem?.id?.toString() || "");
      window.location.href = payment?.url;
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="cardAction">
      <RemoveModal
        show={show}
        onHide={handleClose}
        onDelete={onDelete}
        order={selectedItem}
      />

      <Button
        className="btnSecondary"
        // onClick={() => onDelete(selectedItem?.bookingDetailsId)}
        onClick={() => {
          handleShow();
        }}
        disabled={isDisabled}
      >
        Discard
      </Button>
      <Button
        className="btnPrimary btnIcon"
        disabled={isDisabled}
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: {
              event: "Checkout",
            },
          });
          if (
            paymentMethod === "COD" ||
            selectedItem?.booking_details[0]?.price === 0
          ) {
            COD();
          } else if (paymentMethod === "Card") {
            cardPayment();
          } else toast.error("Select payment method first");
        }}
      >
        <span>
          {paymentMethod === "COD"
            ? `Proceed`
            : `PAY AED ${
                selectedItem?.booking_details[0]?.price
                  ? Number(selectedItem?.booking_details[0]?.price || 0) +
                    selectedItem?.booking_details[0]?.price *
                      (Number(vat) / 100)
                  : 0
              }`}
        </span>{" "}
        <i>
          <Icon icon="ep:arrow-right" />
        </i>
      </Button>
    </div>
  );
};

export default CheckoutCta;
