import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface CategoryInterface {
    uuid: string;
    name: string;
    category_logo: string;
    status: boolean;
    automate_notifications: null | any; // Change 'any' to the appropriate type if known
    notification_title: null | string;
    notification_body: null | string;
    priority: number;
    createdAt: string;
    updatedAt: string;
};

interface Category {
    categories: CategoryInterface[]
    selectedCategory: CategoryInterface
}


const initialState: Category = {
    categories: [],
    selectedCategory: {
        "uuid": "",
        "name": "",
        "category_logo": "",
        "status": true,
        "automate_notifications": null,
        "notification_title": null,
        "notification_body": null,
        "priority": 0,
        "createdAt": "",
        "updatedAt": ""
    }
}

const categoriesSlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        setCategories: (state: Category, action: PayloadAction<CategoryInterface[]>) => {
            state.categories = action.payload;
        },
        setSelectedCategory: (state: Category, action: PayloadAction<CategoryInterface>) => {
            state.selectedCategory = action.payload;
        },
        resetSelectedCategory: (state: Category) => {
            state.selectedCategory = initialState.selectedCategory
        }
    }
})

export const { setCategories, setSelectedCategory, resetSelectedCategory } = categoriesSlice.actions;
export const categoriesSelector = (state: RootState) => state.categories.categories
export const selectedCategorySelector = (state: RootState) => state.categories.selectedCategory
export default categoriesSlice.reducer;