import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { CiMobile2 } from "react-icons/ci";import { FaHandHoldingDollar } from "react-icons/fa6";
import { FaPeopleArrows } from "react-icons/fa";import { SiPolestar } from "react-icons/si";
import { LuUserPlus } from "react-icons/lu";import { LuBadgeCheck } from "react-icons/lu";
import { Ri24HoursFill } from "react-icons/ri";
import { FaRegNewspaper } from "react-icons/fa";
import { IoIosGlobe } from "react-icons/io";
const PartnershipBenefits = () => {
  return (
    <section className="partnership">
      <Container>
        <Row className='g-0 align-items-center'>
          <Col lg={8} className='order-lg-1 order-2'>
            <Row>
              <Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><CiMobile2 size={36} /></div>
                  <h4>Mobile App​</h4>
                  <p>One Mobile App for all the services you need for your car care.​</p>
                </div>
              </Col>
              <Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><FaHandHoldingDollar size={36} /></div>
                  <h4>Increase Revenue​​</h4>
                  <p>Users wash their cars 2 to 3 times a week this is as much as they order food.​​</p>
                </div>
              </Col> 
              <Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><FaPeopleArrows size={36} /></div>
                  <h4>Increase Interaction​​​</h4>
                  <p>Users will interact more with Partners for ordering car services.​</p>
                </div>
              </Col>
               <Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><SiPolestar size={36} /></div>
                  <h4>Stand Out​​​​</h4>
                  <p>92% of our active users referred us and consider Jino to offer the best service in town.​​​</p>
                </div>
              </Col>
              <Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><LuUserPlus size={36} /></div>
                  <h4>New Users​​</h4>
                  <p>New Users Acquisition.​​</p>
                </div>
              </Col>
              <Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><LuBadgeCheck   size={36} /></div>
                  <h4>Quality Control​​</h4>
                  <p>Technician Mobile App/ Admin Dashboard website  for tracking and rating orders.​.​​</p>
                </div>
              </Col><Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><Ri24HoursFill   size={36} /></div>
                  <h4>Roadside Assistance​​</h4>
                  <p>24/7 call support & roadside assistance​.​​</p>
                </div>
              </Col><Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><FaRegNewspaper    size={36} /></div>
                  <h4>Loyalty Program​​​</h4>
                  <p>Points redemption program​.​​</p>
                </div>
              </Col><Col lg={4}>
                <div className="cardBenefits">
                  <div className="icon"><IoIosGlobe   size={36} /></div>
                  <h4>Global Growth​​​</h4>
                  <p>International Partnership opportunities with JINO in KSA, Bahrain, Qatar & Egypt.​​​</p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4} className='order-lg-2 order-1'>
          <h2>Partnership Benefits​</h2>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PartnershipBenefits