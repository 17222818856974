import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const WhyChooseUs = () => {
  return (
    <section className='whyChoose'>
      <Container>
        <Row>
          <Col lg={4}>
            <h3 className="title">wHY CHOOSE US OVER OTHERS?</h3>
          </Col>
          
          <Col lg={4}>
            <div className="card__whyChoose">
              <div className="icon">
                <img src="./images/vision.svg" alt="" />
              </div>
              <h3>VISION</h3>
              <p>JINO strives to spearhead a nation-wide reform in car care services, allowing for convenient car care services to be accessible at any time and in any place.</p>
            </div>
          </Col>
                
          <Col lg={4}>
            <div className="card__whyChoose">
              <div className="icon">
                <img src="./images/mission.svg" alt="" />
              </div>
              <h3>MISSION</h3>
              <p>As innovators in the field of car care services, our mission is to continuing providing top-tier car services for citizens in the UAE in a time-efficient, eco-conscience, and client-convenient manner.</p>
            </div>
          </Col>
              <Col lg={4}>
            <div className="card__whyChoose">
              <div className="icon">
                <img src="./images/jino.svg" alt="" />
              </div>
              <h3>why jino?</h3>
              <p>Why not JINO is more like the question! Time-efficiency, live tracking, experts in the field, one-stop-solutions, and doorsteps services make JINO the to-go-to car-care company. JINO is committed to provide nothing less than the absolute best with our agile team, our diversified services, and our easy-use and accessibility.</p>
            </div>
          </Col>
              <Col lg={4}>
            <div className="card__whyChoose">
              <div className="icon">
                <img src="./images/history.svg" alt="" />
              </div>
              <h3>history</h3>
              <p>JINO was born just a few years ago in 2018! Yet, we have immediately picked up speed and over 50,000 users in just a couple of years. In 2022, the JINO family continues to expand and stands at 1,000,000 orders!</p>
            </div>
          </Col>
              <Col lg={4}>
            <div className="card__whyChoose">
              <div className="icon">
                <img src="./images/value.svg" alt="" />
              </div>
              <h3>core-values</h3>
              <p>JINO’s decision-making processes and day-to-day operations are driven by the following core values:</p>
              <ul>
              <li> - eco-friendly services,</li>
              <li>- customer satisfaction,</li>
              <li>- professional and meticulous assistance,</li>
              <li>- safety,</li>
              <li>- and, most importantly, care!</li>
              </ul>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WhyChooseUs