import { get, post, put, remove } from "./api.service";

export const fetchBookingData = async () => {
  try {
    const res = await get(`booking`);
    if (res?.status) {
      const validBookings = res?.result?.filter(
        (order: any) => order?.booking_details.length > 0
      );
      return validBookings;
    }
  } catch (error) {}
};

export const fetchDayId = async () => {
  try {
    const res = await get(`schedule/days`);
    if (res?.status) {
      return res.result;
    }
  } catch (error) {}
};
export const fetchLevelId = async (lat: any, lng: any) => {
  try {
    const res = await get(`services/getlevelid?lat=${lat}&lng=${lng}`);
    if (res?.status) {
      return res
    }
  } catch (error) {}
};

export const fetchPromoCode = async (params: any) => {
  // promotions?status=true&code=limit1&level_id=379fb2d2-3550-4d75-8425-20eb0b3f8249
  try {
    const res = await post(
      `promotions/check`, params
    );
    if (res?.status) {
      // return res.result[0];
      return res;
    }
  } catch (error) {}
};

export const fetchSlots = async (payload: any) => {
  // const encodedStartTime = encodeURIComponent(startTime);
  //
  try {
    const res = await post(`booking/driver/time-slots`, payload);

    const data = res.result.slots ? res?.result.slots.map((slot: any) => {
      const driver = slot.drivers[0];
      const { start_time, end_original } = slot;
      return {
        start_time,
        end_original,
        uuid: driver?.uuid,
        scheduleId: driver?.schedule?.schedule_id,
      };
    }) : res?.result.map((slot: any) => {
      const driver = slot.drivers[0];
      const { start_time, end_original } = slot;
      return {
        start_time,
        end_original,
        uuid: driver?.uuid,
        scheduleId: driver?.schedule?.schedule_id,
      };
    });

    //
    // const selectedSlot = res?.result.filter( (slot:any) => slot?.start_time === startTime)
    //
    return { data: data, error: null };
    // }
  } catch (error) {
    return { data: null, error: error };
  }
};

export const createBooking = async (payload: any) => {
  // try {

  const res = await post("booking", payload);

  if (res?.status) {
    return { error: null, data: res.result };
  }
  // } catch (error) {

  // return { error, data: null };
  // }
};

export const editBooking = async (payload: any) => {
  console.log("editBooking", payload);

  try {
    const res = await post("booking", payload);
    if (res?.status) {
      return { error: null, data: res.result };
    }
  } catch (error) {
    return { error, data: null };
  }
};

export const updateBooking = async (payload: any) => {
  console.log("updateBooking", payload);
  try {
    const res = await post("booking", payload);
    if (res?.status) {
      return { error: null, data: res.result };
    }
  } catch (error) {
    return { error, data: null };
  }
};

export const duplicateBooking = async (payload: any) => {
  const res = await post("booking/reorder", payload);

  return res;
};

export const deleteBooking = async (payload: any) => {
  try {
    const res = await remove("booking", payload);
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error, data: null };
  }
};

export const proceedToPayment = async (payload: any) => {
  const res = await put("booking/proceedtopay", payload);
  if (res?.status) {
    return { error: null, data: res.result };
  }
};

export const proceedToPaymentCOD = async (payload: any) => {
  const res = await put("booking/cod", payload);

  if (!res?.status) {
    return { error: res?.result?.message, data: null };
  }
  return { error: null, data: res };
};

export const paymentGatewayBooking = async (payload: any) => {
  try {
    const res = await post("booking/p/request", payload);
    if (res?.status) {
      return { error: null, data: res.result };
    }
  } catch (error) {
    return { error, data: null };
  }
};
