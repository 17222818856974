import React from 'react'
import { Container } from 'react-bootstrap'

const OurClients = () => {
  return (
    <section className='our-clients'>  
      <Container>
        <h2>Our Clients​</h2> 
        <ul>
          <li>
            <img src="./images/Clients/avis.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/autostrad.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/bayzat.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/cafu.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/danube.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/europecar.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/dp-world.png" alt="" />
          </li>
        </ul>

           <ul>
          <li>
            <img src="./images/Clients/diamondlease.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/careem.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/justlife.png" alt="" />
          </li>
             <li>
            <img src="./images/Clients/justlife2.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/flogapp.png" alt="" />
          </li>
         
          <li>
            <img src="./images/Clients/hire.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/fresh-express.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/instashop.png" alt="" />
          </li>
           </ul>
     <ul>
          <li>
            <img src="./images/Clients/justlife-prev.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/quick-lease.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/plan-solution.png" alt="" />
          </li>
             <li>
            <img src="./images/Clients/digital-lifestyle.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/emaar.png" alt="" />
          </li>
         
          <li>
            <img src="./images/Clients/legend.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/rizek.png" alt="" />
          </li>
        </ul>
              <ul>
          <li>
            <img src="./images/Clients/syara.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/sel.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/speedy-drive.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/swap.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/universal-standard.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/al-emad.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/afamia.png" alt="" />
          </li>
        </ul>

             <ul>
          <li>
            <img src="./images/Clients/al-barari.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/al-mutakamela.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/al-jadeed.png" alt="" />
          </li>
          <li>
            <img src="./images/Clients/autorent.png" alt="" />
          </li>
              <li>
            <img src="./images/Clients/rizer.png" alt="" />
          </li>
        </ul>
        </Container>
   </section>
  )
}

export default OurClients