import React, { ReactNode } from 'react'
import Header from '../../Header/Header'
import Topbar from '../../Header/Topbar'

const ContainerMain = ({ children }: { children: ReactNode }) => {
  return (
    <div>
        <div className="d-xl-block d-none">
          <Topbar />
        </div>
      <Header/>
          {children}
    </div>
  )
}

export default ContainerMain