import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../../store";
import { toast } from "react-hot-toast";
import LoadingIndicator from "../../Components/loader/loader";
import { getUser, updateUser, uploadImage } from "../../services/user.service";
import { useDispatch } from "react-redux";
import { setAuthUserData } from "../../features/auth.slice";
import ContainerMain from "../../Components/layouts/main/ContainerMain";

const Profile = () => {
  const user = useSelector((state: RootState) => state?.auth?.userData);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const phonNumber = localStorage.getItem(
    "CognitoIdentityServiceProvider.2qi1c9rdl3df6k63c07j4vmls0.LastAuthUser"
  );

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    imagePath: "",
  });

  useEffect(() => {
    if (!user) return;
    setFormData({
      firstName: user?.first_name,
      lastName: user?.last_name,
      email: user?.email,
      imagePath: user?.profile_image,
      phoneNumber: phonNumber!,
    });
  }, [user]);

  const fetchUserData = async () => {
    try {
      const res = await getUser();
      if (!res?.error) dispatch(setAuthUserData(res?.data.result));
    } catch (error) {}
  };

  const handleEditProfile = async () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (formData.email === "") {
      toast.dismiss();
      toast.error("Email can't be empty");
      return;
    }
    if (formData.firstName === "") {
      toast.dismiss();
      toast.error("First Name can't be empty");
      return;
    }
    if (formData.lastName === "") {
      toast.dismiss();
      toast.error("Last Name can't be empty");
      return;
    }
    if (phonNumber === "") {
      toast.dismiss();
      toast.error("Phone number can't be empty");
      return;
    }
    if (!emailRegex.test(formData.email)) {
      toast.dismiss();
      toast.error("Please enter a valid email address.");
      return;
    }
    try {
      // setIsLoading(true);
      const res = await updateUser({
        ...formData,
        phoneNumber: `+${phonNumber}`,
      });

      if (res?.data) {
        await fetchUserData();
      }
      // setIsLoading(false);
      toast.dismiss();
      toast.success("Your profile has been updated successfully!");
    } catch (error) {
      // setIsLoading(false);
      // toast.dismiss();
      // toast.success("Something went wrong");
    }
  };

  const handleImageUpload = async (e: any) => {
    // setIsLoading(true);
    if (e.target.files?.length === 0) {
      toast.error("You must upload an image");
      return;
    }
    try {
      const res: any = await uploadImage(e.target.files[0]);

      if (res.data) {
        setFormData((prev) => {
          return { ...prev, imagePath: res.data };
        });
        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
    }
  };

  const handleInputChange = (event: any) => {
    setFormData((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };
  return (
    <>
      {isLoading && <LoadingIndicator />}

      <ContainerMain>
        <section className="wrapperMain profileMain">
          <Container className="z10">
            <h3 className="title">User Profile</h3>
            <div className="cardProfile">
              <FormLabel className="d-block">Your Profile Picture</FormLabel>

              <FormControl
                type="file"
                id="file"
                hidden
                className="uploadFile"
                onChange={handleImageUpload}
              />
              <label htmlFor="file">
                <div className="placeholderImg">
                  {formData.imagePath && (
                    <img src={formData.imagePath} alt="" className="imgFluid" />
                  )}
                  {!formData.imagePath && (
                    <div className="p-2">
                      <img src="/images/gallery-add.svg" alt="" />
                      <span>Upload your photo</span>
                    </div>
                  )}
                </div>
              </label>

              <hr />
              <Row>
                <Col sm={6}>
                  <FormGroup className="form-group">
                    <FormLabel>First name</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="First name"
                      name="firstName"
                      value={formData?.firstName}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="form-group">
                    <FormLabel>Last name</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Last name"
                      name="lastName"
                      value={formData?.lastName}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="form-group">
                    <FormLabel>Email</FormLabel>
                    <FormControl
                      type="email"
                      placeholder="email"
                      name="email"
                      value={formData?.email}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="form-group">
                    <FormLabel>Phone number</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Phone number"
                      value={formData?.phoneNumber}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="formButton">
                <Button
                  className="btnPrimary btnIcon"
                  onClick={handleEditProfile}
                >
                  <span>Update profile</span>{" "}
                  <i>
                    <Icon icon="ep:arrow-right" />
                  </i>
                </Button>

                {/* <Button variant="reset">Reset</Button> */}
              </div>
            </div>
          </Container>
          <div className="text-center">
            <img src="/images/circle.png" alt="" className="circlebg" />
          </div>
        </section>
      </ContainerMain>
    </>
  );
};

export default Profile;
