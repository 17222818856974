import { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CartSummary from "../../Components/checkout/CartSummary";
import PaymentMethod from "../../Components/checkout/PaymentMethod";
import CheckoutCta from "../../Components/checkout/CheckoutCta";
import OrderDetail from "../../Components/checkout/OrderDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  Booking,
  bookingsSelector,
  resetBooking,
  selectedBookingSelector,
  setBookings,
  setSelectedBooking,
} from "../../features/bookingSlice";
import {
  deleteBooking,
  fetchBookingData,
} from "../../services/booking.service";
import LoadingIndicator from "../../Components/loader/loader";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchCategoriesDetail,
  fetchServices,
} from "../../services/services.service";
import { Service } from "../../Types/services.interface";
import { Category } from "../../Types/category.interface";
import { getApplicationSettings } from "../../services/applicationSetting.service";
import { setSetting } from "../../features/setting.slice";
import ContainerMain from "../../Components/layouts/main/ContainerMain";
import moment from "moment";
import TagManager from "react-gtm-module";

export interface CartItem {
  name: string;
  price: number;
}

const Checkout = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const viewScroll = useRef(null);
  let orders = useSelector(bookingsSelector);
  const selectedItem = useSelector(selectedBookingSelector);
  const [ordersInCart, setOrdersInCart] = useState<Booking[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<string>("");

  const { status } = useParams();

  useEffect(() => {
    if (!status) return;

    sessionStorage.removeItem("setOrderId");
    handleError();
  }, [status]);

  const handleError = () => {
    if (status === "cancelled") {
      toast.error("Order Payment Failed");
      // handlePaymentResponse(3);
    } else if (status === "declined") {
      toast.error("Card Declined");
      // handlePaymentResponse(2);
    }
  };

  const handleDeleteService = async (serviceIdToDelete: any) => {
    // setIsLoading(true);
    const payload = {
      uuid: serviceIdToDelete,
      type: "service",
    };

    const res = await deleteBooking(payload);
    if (res)
      if (res?.data) {
        // setIsLoading(false);
        getBookings();
        if (
          serviceIdToDelete.includes(selectedItem?.booking_details[0]?.uuid)
        ) {
          dispatch(setSelectedBooking(null));
        }
        return;
      }
    // setIsLoading(false);
    toast.error("Couldn't delete service booking. Try later!");
  };

  const handleSelectedItem = (order: Booking) => {
    dispatch(setSelectedBooking(order));
  };

  const handlePaymentMethod = (method: string) => {
    setPaymentMethod(method);
  };

  const handleInCart = () => {
    const inCart = orders.filter(
      (order) =>
        order?.booking_status_id === 1 && order?.booking_details?.length > 0
    );
    const oldBookings = inCart.filter((booking) =>
      moment(new Date(booking.booking_details[0]?.bookingAt)).isBefore(
        new Date()
      )
    );
    if (oldBookings.length > 0) {
      // handleDeleteService(
      //   oldBookings.map((booking) => booking.booking_details[0]?.uuid)
      // );
    }
    if (state) {
      const order = orders.find((order) => order.uuid === state);
      if (order) dispatch(setSelectedBooking(order));
    }
    if (inCart.length === 0) {
      dispatch(setSelectedBooking(null));
    }
    setOrdersInCart(inCart);
  };

  // useEffect(() => {
  //   (orders.length === 1 || !selectedItem) && setSelectedItem(null);
  // }, [handleDeleteService]);

  useEffect(() => {
    //@ts-ignore
    viewScroll.current.scrollIntoView({
      behaviour: "smooth",
    });
    getApplicationConfigs();
    fetchServices().then((res) => {
      setServices(res?.data.result);
      fetchCategoriesDetail().then((res) => {
        setCategories(res?.data.result);
      });
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "Checkout",
      },
    });
  }, []);

  useEffect(() => {
    // Fetch booking data when the component mounts
    getBookings();
  }, [dispatch]);

  const getBookings = () => {
    fetchBookingData()
      .then((response) => {
        dispatch(setBookings(response || []));
      })
      .catch((err) => console.log(err));
  };

  const getApplicationConfigs = async () => {
    try {
      const res = await getApplicationSettings();
      dispatch(setSetting(res?.data?.result));
    } catch (error) {}
  };

  useEffect(() => {
    // Handle inCart logic whenever orders change
    if (orders.length > 0) {
      handleInCart();
    }
  }, [orders]);

  const discount: number = 0; // for now there is no discount

  return (
    <>
      <ContainerMain>
        <section className="wrapperMain" ref={viewScroll}>
          <Container className="z10">
            <div className="caption">CHECKOUT</div>
            <div className="checkout">
              <Row className="g-0">
                <Col lg={6}>
                  <div className="orderDetail">
                    <img src="/images/logo.png" alt="" className="brandName" />

                    <div className="orderHeader">
                      <h3>Order Details</h3>
                      <div className="total">
                        Total ( <span>{ordersInCart?.length}</span> )
                      </div>
                    </div>

                    <OrderDetail
                      services={services}
                      categories={categories}
                      orders={ordersInCart}
                      handleSelectItem={handleSelectedItem}
                      selectedItem={selectedItem}
                      onDelete={handleDeleteService}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`${
                      !selectedItem?.uuid ? "opacityLow" : ""
                    } paymentCard `}
                  >
                    <div className="wrapperCart">
                      <PaymentMethod
                        selectedItem={selectedItem}
                        methodSelector={handlePaymentMethod}
                      />
                      {/* <CartSummary items={items} discount={discount} selectedItem={ selectedItem }/> */}
                      <CartSummary
                        discount={discount}
                        selectedItem={selectedItem}
                        handleSelectItem={handleSelectedItem}
                      />
                    </div>
                    <CheckoutCta
                      selectedItem={selectedItem}
                      onDelete={handleDeleteService}
                      paymentMethod={paymentMethod}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="text-center">
            <img src="/images/circle.png" alt="" className="circlebg" />
          </div>
        </section>
        {/* <RemoveModal/> */}
      </ContainerMain>
    </>
  );
};

export default Checkout;
