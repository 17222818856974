import React from "react";
import { Container } from "react-bootstrap";

const OurEstablishment = () => {
  return (
    <>
    <section className="ourEstablishment">
      <Container>
      <h2>Our ESTABLISHMENT</h2>
      <ul>
        <li>
          <div className="icon">
            <img src="./images/setting.svg" alt="" />
          </div>
          <div className="media-body">
            <h4>2018</h4>
            <p>Jino Born</p>
          </div>
        </li>
          <li>
          <div className="media-body">
            <h4>2020</h4>
            <p>Started our B2B business with car rentals, showroom & communities.</p>
          </div>
          <div className="icon">
            <img src="./images/b2b.svg" alt="" />
          </div>
        </li>
             <li>
          <div className="media-body">
            <h4>2022</h4>
            <p>Launching our new app expending our team to reach 1,000,000 Orders.</p>
          </div>
          
          <div className="icon">
            <img src="./images/mobile.svg" alt="" /></div>
        </li>
            <li>
          <div className="media-body">
              <h4>2024</h4>
            <p>Launching new app and website experience</p>
          </div>
          
          <div className="icon">
            <img src="./images/computer.svg" alt="" /></div>
        </li>
            <li>
              
          <div className="icon">
            
            <img src="./images/covid.svg" alt="" />
          </div>
          <div className="media-body">
            <h4>2019</h4>
            <p>Started our B2B business with car rentals, showroom & communities.</p>
          </div>
        </li>
             <li>
              
          <div className="icon">
            <img src="./images/user.svg" alt="" /></div>
          <div className="media-body">
            <h4>2021</h4>
            <p>Launching our new app expending our team to reach 1,000,000 Orders.</p>
          </div>
          
        </li>
             <li>
              
          <div className="icon">
            <img src="./images/calendar-03.svg" alt="" /></div>
          <div className="media-body">
            <h4>2023</h4>
            <p>Launched booking through website and expanded our products offering.</p>
          </div>
          
        </li>
        </ul>
      <img src="./images/bgLogo.svg" alt="" className="logo" />
      </Container>
      </section>
    </>
  );
};

export default OurEstablishment;
