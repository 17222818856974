import React, { useState, useEffect, FormEvent } from "react";
import { Icon } from "@iconify/react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import Otp from "./Otp";
import Amplify from "aws-amplify";
import Auth from "@aws-amplify/auth";
import awsconfig from "../../../aws-exports";
import LoadingIndicator from "../../loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, setAuthUserData } from "../../../features/auth.slice";
import toast from "react-hot-toast";
import EditProfile from "./EditProfile";
import {
  getUser,
  signinUser,
  updateUser,
} from "../../../services/user.service";
import { User } from "../../../Types/user.interface";
import { RootState } from "../../../store";
import TagManager from "react-gtm-module";
import { get } from "../../../services/api.service";
import { setUser } from "../../../features/user.slice";
import spinnerSvc from "../../../services/spinner-service";
Amplify.configure(awsconfig);

const Login = (props: any) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberCopy, setPhoneNumberCopy] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);

  const handleOnChange = (value: string) => {
    setPhoneNumberCopy(value);

    // dispatch(setPhoneNumber)
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [profileModalShow, setProfileModalShow] = React.useState(false);

  // useEffect(() => {
  //
  // },[profileModalShow])

  const handleHideAndShowModal = () => {
    props.onHide(); // Hide the current modal
    setModalShow(true); // Show the new modal
  };

  const signIn = (forVerifyOtp?: boolean) => {
    const phone: any = phoneNumberCopy ? phoneNumberCopy : phoneNumber;
    spinnerSvc.start();
    // if (phone.startsWith("97") || phone.startsWith("92")) {
    Auth.signIn(`+${phone}`)
      .then((result) => {
        spinnerSvc.stop();
        TagManager.dataLayer({
          dataLayer: {
            event: "Create Account",
          },
        });
        setSession(result);
        if (!forVerifyOtp) {
          handleHideAndShowModal(); // Show the new modal
          // setIsLoading(false);
        }
      })
      .catch((e) => {
        toast.dismiss();
        spinnerSvc.stop();
        toast.error("Sorry, we encountered an error... Try again in a moment");
        // setIsLoading(false);
      });
  };

  const handleSaveProfile = async (payload: User) => {
    const nameRegex = /^[A-Za-z\s]{1,20}$/;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (payload.email === "") {
      toast.dismiss();

      toast.error("Email can't be empty");
      return;
    }
    if (payload.firstName === "") {
      toast.dismiss();

      toast.error("First Name can't be empty");
      return;
    }
    if (payload.lastName === "") {
      toast.dismiss();

      toast.error("Last Name can't be empty");
      return;
    }
    if (phoneNumber === "") {
      toast.dismiss();

      toast.error("Phone number can't be empty");
      return;
    }
    if (!nameRegex.test(payload.firstName)) {
      toast.dismiss();

      toast.error(
        "First Name should only contain alphabetical characters (A-Z or a-z)."
      );
      return;
    }
    if (!nameRegex.test(payload.lastName)) {
      toast.dismiss();

      toast.error(
        "Last Name should only contain alphabetical characters (A-Z or a-z)."
      );
      return;
    }
    if (!emailRegex.test(payload.email)) {
      toast.dismiss();

      toast.error("Please enter a valid email address.");
      return;
    }
    try {
      // setIsLoading(true);

      const res = await updateUser({
        ...payload,
        // phoneNumber: `+${phoneNumber}`,
      });
      if (isNewUser) storeTokens();
      if (res?.data) {
        setProfileModalShow(false);
        await fetchUserData();
      }
      setProfileModalShow(false);
      toast.success("Profile completed successfully");
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      toast.dismiss();
      toast.error("Sorry, we encountered an error... Try again in a moment");
    }
  };

  const fetchUserData = async (token?: any) => {
    try {
      const res = await getUser();
      if (!res?.data?.result?.first_name) {
        // setIsLoading(false);
        setProfileModalShow(true);
        return;
      }
      if (!res?.error) {
        localStorage.setItem("Token", token || "");
        dispatch(setUser(res.data.result));
        dispatch(loadUser(res.data.result));
        dispatch(setAuthUserData(res?.data.result));
      }
    } catch (error) {}
  };

  const verifyOtp = async (otp: string) => {
    spinnerSvc.start();
    Auth.sendCustomChallengeAnswer(session, otp)
      .then(async (user) => {
        spinnerSvc.stop();
        let uuid = user?.attributes?.["custom:userId"];
        if (uuid) {
          setModalShow(false);
          props.onHide();
          localStorage.setItem("userKey", "1");
          localStorage.setItem("uuid", uuid);
          let token = user?.signInUserSession?.idToken?.jwtToken;
          localStorage.setItem("loginTime", new Date().getTime().toString());
          fetchUserData(token);
          props.onHide();
        } else {
          toast.dismiss();
          toast.error("Invalid one time password");
        }
      })
      .catch((err) => {
        spinnerSvc.stop();
        toast.dismiss();
        toast.error("Invalid one time password");
      });
  };

  const signInWithBackEnd = async () => {
    const phone: any = phoneNumberCopy ? phoneNumberCopy : phoneNumber;

    try {
      const res = await signinUser(`+${phone}`);
      if (!res?.error) {
        setIsNewUser(res?.data.result.new);
        return res?.data.result;
      }
    } catch (error) {}
  };

  const storeTokens = (): any => {
    setTimeout(() => {
      const rtToken = localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.+${phoneNumber}.refreshToken`
      );
      const idToken = localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.+${phoneNumber}.idToken`
      );
      const atToken = localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.+${phoneNumber}.accessToken`
      );

      if (rtToken && idToken && atToken) {
        localStorage.setItem("rtToken", rtToken!);
        localStorage.setItem("idToken", idToken!);
        localStorage.setItem("atToken", atToken!);
        if (!isNewUser) fetchUserData();
        // setIsLoading(false);
        if (isNewUser) {
          setProfileModalShow(true);
          // setIsLoading(false);
        }
        setModalShow(false);
        return { otpVerified: true };
      } else {
        toast.dismiss();
        toast.error("Invalid OTP, Please enter valid OTP");
        // setIsLoading(false);
        return { otpVerified: false };
      }
    }, 3000);
  };

  const exitFunction = () => {
    setPhoneNumber(phoneNumberCopy);
    setPhoneNumberCopy("");
  };

  const handleAuth = async (e?: any | FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (phoneNumberCopy.length < 7) {
      toast.dismiss();
      toast.error("Invalid phone number");
      return;
    }
    setPhoneNumber(phoneNumberCopy);
    try {
      // setIsLoading(true);
      let res = await signInWithBackEnd();
      signIn();
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    }
  };
  //
  return (
    <>
      {isLoading && (
        <div className="loaderCst" style={{ zIndex: "99999" }}>
          <LoadingIndicator />
        </div>
      )}
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="customModal"
        onExit={exitFunction}
      >
        <Modal.Body>
          <Button
            onClick={() => {
              props.onHide();
              setPhoneNumber("");
            }}
            variant="btnIcon"
          >
            <Icon icon="akar-icons:cross" />
          </Button>
          <div className="form-card">
            <img src="/images/amico.png" alt="" className="mb-4" />
            <h3>Jump start with our JINO platform</h3>
            <p>Just enter your phone number to Sign in</p>
            <form onSubmit={handleAuth}>
              <div className="form-group">
                <div className="relative">
                  <Form.Group className="mb-3">
                    <PhoneInput
                      country={"ae"}
                      value={phoneNumberCopy}
                      onChange={handleOnChange}
                      onEnterKeyPress={handleAuth}
                      inputProps={{
                        autoFocus: true,
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Button
                      type="submit"
                      className="btnSecondary w-100 text-uppercase"
                    >
                      Next{" "}
                      <i>
                        <Icon icon="ep:arrow-right" />
                      </i>
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Otp
        show={modalShow}
        reSignIn={signIn}
        onHide={() => setModalShow(false)}
        verification={(otp: string) => verifyOtp(otp)}
      />
      <EditProfile
        show={profileModalShow}
        onHide={() => setProfileModalShow(false)}
        onSubmission={handleSaveProfile}
        phoneNumber={`+${phoneNumber}`}
      />
    </>
  );
};

export default Login;
