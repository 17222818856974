import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Booking } from "../../features/bookingSlice";

const RemoveModal = ({
  show,
  onHide,
  onDelete,
  order,
  multOrder,
}: {
  show: any;
  onHide: any;
  onDelete: any;
  order: Booking | null;
  multOrder?: any;
}) => {
  //
  return (
    <Modal
      className="descardmodal"
      show={show}
      onHide={onHide}
      animation={false}
    >
      <Modal.Header className="mx-auto">
        <Modal.Title className="fs-6">
          Are you sure to delete booking(s)?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-auto p-1">
        <div className="cardAction">
          <Button
            className="btnSecondary descard"
            onClick={() => {
              onHide();
            }}
          >
            NO
          </Button>
          <Button
            onClick={() => {
              multOrder
                ? onDelete()
                : onDelete([order?.booking_details[0]?.uuid]);
              onHide();
            }}
            className="btnPrimary btnIcon"
          >
            Yes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveModal;
