import React, { ReactNode } from 'react'
import Footer from '../../footer/Footer'

const ContainerSecondary = ({ children }: { children: ReactNode}) => {
  return (
      <div>
  
      {children}
      <Footer/>
    </div>
  )
}

export default ContainerSecondary