import React from 'react'
import { Container } from 'react-bootstrap'

const OurPartners = () => {
  return (
    <section className='our-partners'>
      <Container>
        <h2>Our Partners​</h2>
        <ul>
          <li>
            <img src="./images/Partners/avis.png" alt="" />
          </li>
           <li>
            <img src="./images/Partners/TotalEnergies_logo.svg.png" alt="" />
          </li>
           <li>
            <img src="./images/Partners/shell.png" alt="" />
          </li>
           <li>
            <img src="./images/Partners/tasra.png" alt=""  />
          </li>
        </ul>
        <br />
            <ul>
          <li>
            <img src="./images/Partners/mobil_uae_logo.png" alt="" />
          </li>
           <li>
            <img src="./images/Partners/EasyLease.jpeg" alt="" />
          </li>
           <li>
            <img src="./images/Partners/Digital-Gravity-logo-profile.png" alt="" />
          </li>
           <li>
            <img src="./images/Partners/WURTH.png" alt="" />
          </li>
           <li>
            <img src="./images/Partners/Ecowide.webp" alt="" />
          </li>
        </ul>
      </Container>
    </section>
  )
}

export default OurPartners