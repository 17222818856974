import { get } from "./api.service";

export const getApplicationSettings = async () => {
 
    try {
      const res = await get(`applicationsetting`);
      if (res?.status){
          
          // 
          return {error: null, data: res};
      }
    } catch (error) {
      return {error: error, data: null};
    }
  };