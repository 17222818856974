import { get } from "./api.service";

export const fetchServicesData = async (
  selectedCarTypeId: string,
  selectedCategoryId: string,
  levelId?: string
) => {
  const level_Id = levelId ? levelId : "91769678-2427-4e72-b240-315fe948a135";
  try {
    const res = await get(
      `services/configs?level_id=${level_Id}&vehicle_type_id=${selectedCarTypeId}&service_category_id=${selectedCategoryId}&status=${true}`
    );
    if (res?.status) {
      const date = new Date()
      return {
        error: null,
        data: res.result
          .filter((a: any) => a.service.status)
          .sort((a: any, b: any) => a.price - b.price).map((item: any) => {
            if (item?.in_promotion?.length > 0) {
              var findInPromotion = item?.in_promotion.find(
                (aItem: any) =>
                  new Date(aItem?.start_date) <= date &&
                  new Date(aItem?.end_date) >= date &&
                  aItem?.status,
              );
              if (findInPromotion) {
                var calDiscount =
                  item?.price * (findInPromotion?.discount_percentage / 100);
                calDiscount = item?.price - calDiscount;
                item.promotion_id = findInPromotion.uuid;
                item.oldPrice = item?.price;
                item.price = calDiscount;
              } else {
                item.oldPrice = null;
              }
            }
            return item
          })
      };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};

export const fetchServicesDescription = async (params: any) => {
  // const level_Id = levelId? levelId : "79ef39c0-f0f6-421a-b327-4e444948a55a";
  try {
    const res = await get(`services/configs`, params);
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};

export const fetchServicesConfigView = async (params: any) => {
  try {
    const res = await get(`services/configs/view`, params);
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};
export const fetchServices = async () => {
  try {
    const res = await get(`services`);
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};

export const fetchCategoriesDetail = async () => {
  // const level_Id = levelId? levelId : "79ef39c0-f0f6-421a-b327-4e444948a55a";
  try {
    const res = await get(`services/category`);
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};
