import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Promotion } from "./promotion.slice";
import { CategoryInterface } from "./categories.slice";
import { RootState } from "../store";
export interface Service {
  uuid: string;
  name: string;
  short_description: string;
  service_category_id: string;
  description: string;
  images: string[];
  jsonDump: any; // You may want to define a type for this
  status: boolean;
  video: string[];
  priority: number;
  is_most_selling: boolean;
  is_quote: boolean;
  tags: string[];
  createdAt: string;
  updatedAt: string;
  service_type_id: string | null;
  category?: CategoryInterface;
}

export interface UpsellConfig {
  uuid: string;
  upsell_id: string;
  service_id: string;
  vehicle_type_id: string;
  price: number;
  status: boolean;
  selected?: boolean;
  upsell: Upsell;
  createdAt: string;
  updatedAt: string;
}

interface Upsell {
  uuid: string;
  name: string;
  status: boolean;
}

export interface ServiceConfig {
  uuid: string;
  brand_id: string | null;
  model_id: string | null;
  engine_id: string | null;
  service_id: string;
  vehicle_type_id: string;
  price: number;
  jsonDump: any; // You may want to define a type for this
  level_id: string;
  level_type: string;
  status: boolean;
  processing_time: number;
  buffer_period: number;
  is_most_selling: boolean;
  deprecated: boolean;
  createdAt: string;
  updatedAt: string;
  price_id: string | null;
  upsells: UpsellConfig[];
  service: Service | null;
  in_promotion: Promotion[];
  related_value: any;
  vehicle_type: any;
  total: number;
}

interface ServiceSlice {
  serviceConfigs: ServiceConfig[];
  selectedConfig: ServiceConfig | null;
  services: Service[];
}

const initialState: ServiceSlice = {
  serviceConfigs: [],
  selectedConfig: null,
  services: [],
};

const serviceConfigSlice = createSlice({
  initialState,
  name: "service_configs",
  reducers: {
    setServiceConfigs: (
      state: ServiceSlice,
      action: PayloadAction<ServiceConfig[]>
    ) => {
      state.serviceConfigs = action.payload;
    },
    setSelectedConfig: (
      state: ServiceSlice,
      action: PayloadAction<ServiceConfig | null>
    ) => {
      state.selectedConfig = action.payload;
    },
    resetSelectedConfig: (state: ServiceSlice) => {
      state.selectedConfig = null;
    },
  },
});

export const { setSelectedConfig, setServiceConfigs, resetSelectedConfig } =
  serviceConfigSlice.actions;
export const selectedConfigSelector = (state: RootState) =>
  state.service_config.selectedConfig;
export const configsSelector = (state: RootState) =>
  state.service_config.serviceConfigs;
export default serviceConfigSlice.reducer;
