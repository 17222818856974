import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Settings {
    uuid: string;
    vat: string;
    vat_num: string;
    whatsapp: string;
    refund_days: number;
    email: string;
    phone_number: string;
}

interface ApplicationSetting {
    settings: Settings
}

const initialState: ApplicationSetting = {
    settings: {
        uuid: '',
        vat: '0',
        whatsapp: '',
        vat_num: '',
        refund_days: 20,
        email: '',
        phone_number: '',
    }
}

const settingSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        setSetting: (state: ApplicationSetting, action: PayloadAction<Settings>) => {
            state.settings = action.payload
        }
    }
})

export const { setSetting } = settingSlice.actions
export const settingSelector = (state: RootState) => state.setting.settings;
export default settingSlice.reducer;