import React from "react";
import { Form } from "react-bootstrap";
import { proceedToPaymentCOD } from "../../services/booking.service";
import { Booking } from "../../features/bookingSlice";

interface Props {
  selectedItem: Booking | null;
  methodSelector: (method: string) => void;
}

const PaymentMethod: React.FC<Props> = ({ selectedItem, methodSelector }) => {
  let isDisabled: boolean = false;
  selectedItem?.uuid ? (isDisabled = false) : (isDisabled = true);

  return (
    <div className="paymentWrapper">
      <div className="orderHeader mb-3">
        <h3>Payment Methods</h3>
      </div>
      <Form.Check className="paymentMethod">
        <Form.Check.Input
          type="radio"
          id="payment"
          name="payment"
          hidden
          disabled={isDisabled}
          onClick={() => {
            methodSelector("COD");
          }}
        />
        <label htmlFor="payment" className="pay">
          <span></span>
          <div className="flex-1">
            <h4>Pay on Delivery</h4>
            <p>Pay with cash on delivery</p>
          </div>
        </label>
      </Form.Check>

      <Form.Check className="paymentMethod">
        <Form.Check.Input
          type="radio"
          id="payment2"
          name="payment"
          hidden
          disabled={isDisabled}
          onClick={() => {
            methodSelector("Card");
          }}
        />
        <label htmlFor="payment2" className="pay">
          <span></span>
          <div className="flex-1">
            <h4>Credit/Debit Cards</h4>
            <p>Pay with your Credit / Debit Card</p>
          </div>
          <ul className="brandCard">
            <li>
              <img src="/images/visa.svg" alt="" />
            </li>
            <li>
              <img src="/images/american express.svg" alt="" />
            </li>
            <li>
              <img src="/images/mestro.svg" alt="" />
            </li>
          </ul>
        </label>
      </Form.Check>
    </div>
  );
};

export default PaymentMethod;
