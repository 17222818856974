import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PaginationMain from "../../Components/widgets/PaginationMain";
import HistoryRecord from "../../Components/booking/HistoryRecord";
import {
  fetchBookingData,
  duplicateBooking,
} from "../../services/booking.service";
import { useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import RemoveModal from "../../Components/checkout/RemoveModal";
import { deleteBooking } from "../../services/booking.service";
import { toast } from "react-hot-toast";
import LoadingIndicator from "../../Components/loader/loader";
import { setGuestSelectedUser } from "../../features/guestUserServiceSlice";
import {
  Booking,
  bookingsSelector,
  resetBooking,
  setBookings,
} from "../../features/bookingSlice";
import ContainerMain from "../../Components/layouts/main/ContainerMain";

interface Record {
  id: number;
  service: {
    title: string;
    description: string;
  };
  location: string;
  amount: string;
  date: string;
  time: string;
  status: string;
}

const BookinHistory = () => {
  const orders = useSelector(bookingsSelector);
  const path = window.location.pathname;
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedRecordsId, setSelectedRecordsId] = useState<string[]>([]);
  const [bookingRecord, setBookingRecord] = useState<Booking[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<Booking[]>([]);
  const [searchValue, setSearchValue] = useState<any>();
  const [sortDescending, setSortDescending] = useState<boolean>(true);
  const [selectedBooking, setSelectedBooking] = useState<any>();
  const [showRemModal, setShowRemModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteSelected, setIsDeleteSelected] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (orders.length === 0) return;
    handleInCartBookings();
    sessionStorage.removeItem("setOrderId");
  }, [orders, sortDescending]);

  useEffect(() => {
    console.log("bookingGet");
    getBookings();
    // fetchBookingData()
    //   .then((response) => {
    //     dispatch(setBookings(response || []));
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  const getBookings = async () => {
    // dispatch()
    dispatch(resetBooking());

    const res = await fetchBookingData();
    dispatch(setBookings(res));
  };

  const handleInCartBookings = () => {
    const bookingHistory = orders.filter(
      (order) =>
        order?.booking_details[0]?.booking_status_id &&
        order?.booking_details[0]?.booking_status_id !== 1
    );

    bookingHistory?.sort((a, b) =>
      sortDescending ? b.id || 0 - (a.id || 0) : a.id || 0 - (b.id || 0)
    );
    setFilteredRecords(bookingHistory);
    setBookingRecord(bookingHistory);
  };

  const handleDeleteService = async (serviceIdToDelete: any) => {
    // setIsLoading(true);
    const payload = {
      uuid: [serviceIdToDelete],
      type: "service",
    };
    const res = await deleteBooking(payload);

    if (res) {
      // setIsLoading(false);
    }
    if (res?.data?.status) {
      return;
    }
    // setIsLoading(false);

    toast.error("Couldn't delete service booking. Try later!");
  };

  const handleDeleteSelected = async () => {
    // setIsLoading(true);
    const statusCheck = selectedRecords.filter(
      (order: Booking) => order?.booking_details[0]?.booking_status_id === 6
    );

    if (statusCheck.length > 0) {
      const orderIds = selectedRecords.map(
        (order: Booking) => order?.booking_details[0].uuid
      );

      const payload = {
        uuid: orderIds,
        type: "service",
      };
      const res = await deleteBooking(payload);

      if (res) {
        // setIsLoading(false);
        setIsDeleteSelected(false);
        setSelectedRecords([]);
      }
      if (res?.data?.status) {
        setIsDeleteSelected(false);
        return;
      }
      // setIsLoading(false);
      toast.dismiss();
      toast.error("Couldn't delete service booking. Try later!");
      return;
    }
    // setIsLoading(false);
    toast.dismiss();

    toast.error(
      "Incomplete order! Can't delete booking from history. To cancel, please refer to the app."
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    const filtered = filteredRecords?.filter((record) => {
      const idAsString = record?.id?.toString().toLowerCase();
      const locationName =
        record?.booking_details[0]?.customer_address?.area?.toLowerCase();
      const serviceName =
        record?.booking_details[0]?.service_config?.service?.name.toLowerCase();
      const bookingDate = record?.booking_details[0]?.bookingAt?.toLowerCase();
      if (
        idAsString?.includes(value.toLowerCase()) ||
        locationName?.includes(value.toLowerCase()) ||
        serviceName?.includes(value.toLowerCase()) ||
        bookingDate?.includes(value.toLowerCase())
      ) {
        return record;
      }
    });
    value ? setFilteredRecords(filtered) : setFilteredRecords(bookingRecord);
  };

  useEffect(() => {
    if (selectedRecords?.length > 0) {
      setIsDeleteSelected(true);
      return;
    }
    setIsDeleteSelected(false);
  }, [selectedRecords]);

  const handleCloseRemoveModal = () => {
    setShowRemModal(false);
    setIsDeleteSelected(false);
    setSelectedRecords([]);
    setSelectedRecordsId([]);
  };
  const handleShowRemoveModal = () => setShowRemModal(true);

  return (
    <>
      <ContainerMain>
        <RemoveModal
          multOrder={isDeleteSelected}
          show={showRemModal}
          onHide={handleCloseRemoveModal}
          onDelete={
            isDeleteSelected ? handleDeleteSelected : handleDeleteService
          }
          order={isDeleteSelected ? selectedRecords : selectedBooking} //selectedRecords is for orders to delete with checkbox and selectedBooking is for deleting from action dropdown
        />

        <section className="wrapperMain">
          <Container className="z10">
            <div className="titlePage">
              <Row>
                <Col lg={4} md={6}>
                  <h3>Booking History</h3>
                </Col>
                <Col lg={8} md={6} className="text-end">
                  <Button
                    className="btnPrimary btnIcon"
                    onClick={() => {
                      dispatch(
                        setGuestSelectedUser({
                          category: null,
                          carType: null,
                          service: null,
                        })
                      );
                      navigate("/");
                    }}
                  >
                    <span>Add new booking</span>{" "}
                    <i>
                      <Icon icon="ep:arrow-right" />
                    </i>
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="searchHistory">
              <Row className="align-items-center">
                <Col lg={4} sm={6}>
                  <Form.Control
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    onKeyDown={() => setFilteredRecords(bookingRecord)}
                  />
                </Col>
                <Col lg={8} sm={6} className="text-end">
                  <Button
                    className="delte-servics active"
                    disabled={!isDeleteSelected}
                    onClick={handleShowRemoveModal}
                  >
                    <img className="me-1" src="images/trash.svg" alt="" />{" "}
                    DELETE
                  </Button>
                  {/* <PaginationMain
                            totalRecords={filteredRecords.length}
                            recordsPerPage={10} // Assuming 10 records per page
                            onPageChange={handlePageChange}/> */}
                </Col>
              </Row>
            </div>
            <div className="tableWrapper">
              <div className="cstTableHistory">
                <HistoryRecord
                  records={filteredRecords}
                  sortDescending={sortDescending}
                  setSortDescending={setSortDescending}
                  setSelectedBooking={setSelectedBooking}
                  handleShowRemoveModal={handleShowRemoveModal}
                  setIsDeleteSelected={setIsDeleteSelected}
                  isDeleteSelected={isDeleteSelected}
                  selectedRecords={selectedRecords}
                  setSelectedRecords={setSelectedRecords}
                  selectedRecordsId={selectedRecordsId}
                  setSelectedRecordsId={setSelectedRecordsId}
                />
              </div>
            </div>
          </Container>
          <div className="text-center">
            <img src="/images/circle.png" alt="" className="circlebg" />
          </div>
        </section>
      </ContainerMain>
      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default BookinHistory;
