import React from "react";
import ReactDOM from "react-dom/client";

import "react-phone-input-2/lib/style.css";
import "simplebar-react/dist/simplebar.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./global.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import { Toaster } from "react-hot-toast";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-M3RPZKQ",
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <Toaster
      toastOptions={{
        style: {
          maxWidth: "800px",
        },
        position: "bottom-center",
      }}
    />
    <App />
  </Provider>
);
