import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CartList from "../../widgets/CartList";
import { useDispatch, useSelector } from "react-redux";
import { Booking, bookingsSelector } from "../../../features/bookingSlice";
import { deleteBooking } from "../../../services/booking.service";
import LoadingIndicator from "../../loader/loader";
import { toast } from "react-hot-toast";
import RemoveModal from "../../checkout/RemoveModal";
// const cartData = [
//   {
//     id: 1,
//     iconSrc: "/images/carWash.svg",
//     title: "Bronze Waterless Wash",
//     location: "Home Topgolf - Emirates Hills 2 - Dubai - UAE",
//     carInfo: "Honda Civic - CSX-1390",
//     price: "AED 65",
//   },
//   {
//     id: 2,
//     iconSrc: "/images/carWash.svg",
//     title: "Silver Car Detailing",
//     location: "Office Tower - Business Bay - Dubai - UAE",
//     carInfo: "Toyota Camry - DXY-7891",
//     price: "AED 150",
//   },
//   // Add more cart data objects as needed
// ];

const CartDropdown = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const orders = useSelector(bookingsSelector);
  const [cartToggle, setCartToggle] = useState(false);
  const [ordersInCart, setOrdersInCart] = useState<Booking[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<any>();
  const [isClearingCart, setIsClearingCart] = useState(false);

  useEffect(() => {
    if (!orders) return;
    handleInCartBookings();
    //
  }, [orders]);
  const handleInCartBookings = () => {
    const cartOrders = orders.filter(
      (order: any) => order?.orderStatus?.int_id === 1
    );
    setOrdersInCart(cartOrders);
  };
  const handleCartClose = () => {
    //

    setCartToggle(false);
  };
  //clear all
  const handleClearCart = async () => {
    // dispatch(setOrdersEmpty());
    // setIsLoading(true);
    // const cartOrders = orders.filter((order) => order?.orderStatus?.int_id === 1);

    const payload = {
      uuid: ordersInCart.map((order) => {
        const IDs = order.uuid;
        return IDs;
      }),
      type: "service",
    };

    const res = await deleteBooking(payload);
    if (res) {
      // setIsLoading(false);
      if (location.pathname === "/booking-history") {
      }
    }
    // setIsLoading(false);
    toast.dismiss();

    if (!res?.data?.status)
      toast.error("Couldn't delete service booking. Try later!");
  };

  //delete specific service
  const handleDeleteService = async (serviceIdToDelete: string) => {
    // setIsLoading(true);
    const payload = {
      uuid: [serviceIdToDelete],
      type: "service",
    };
    const res = await deleteBooking(payload);
    if (res)
      if (res?.data?.status) {
        // setIsLoading(false);
        // dispatch(setIsReRenderBooking(true));

        return;
      }
    // setIsLoading(false);
    toast.dismiss();
    toast.error("Couldn't delete service booking. Try later!");
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //
  //
  return (
    <>
      <RemoveModal
        show={show}
        onHide={handleClose}
        onDelete={isClearingCart ? handleClearCart : handleDeleteService}
        order={selectedBooking}
        multOrder={isClearingCart}
      />

      <Dropdown show={cartToggle} onToggle={setCartToggle}>
        <Dropdown.Toggle variant="shoping">
          <Link to="javascript:void(0)" className="cartLink">
            <Icon icon="bytesize:cart" onClick={() => setCartToggle(true)} />{" "}
            {ordersInCart?.length > 0 && <span>{ordersInCart.length}</span>}{" "}
          </Link>
        </Dropdown.Toggle>

        {
          <Dropdown.Menu align="end">
            <div className="dropdownTitle">
              <h3>YOUR CART</h3>
              <Button variant="close" onClick={handleCartClose}>
                <Icon icon="clarity:close-line" />
              </Button>
            </div>
            <div className="cardBody youcart">
              <div>
                {ordersInCart.length === 0 && (
                  <>
                    <div className="text-center  noCart">
                      <img
                        src="/images/noCart.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </>
                )}
                {/* {ordersInCart?.length >= 1 &&
                  ordersInCart
                    ?.slice(0, ordersInCart.length)
                    ?.map((cartItem, index) => (
                      <CartList
                        key={index}
                        cartItem={cartItem}
                        icon={cartItem?.category?.category_logo}
                        title={cartItem?.service?.service?.name}
                        location={cartItem?.locationName}
                        carInfo={`${cartItem?.manufacturer?.name} ${cartItem?.model} - ${cartItem?.plateNumberInitial}-${cartItem?.plateNumberSecond}`}
                        price={cartItem?.servicePrice}
                        // onDelete={handleDeleteService}
                        setSelectedBooking={setSelectedBooking}
                        handleShow={handleShow}
                      />
                    ))} */}
              </div>
            </div>
            <div className="cartFooter">
              {ordersInCart?.length > 0 && (
                <Link
                  to="#"
                  className="ctaRemove"
                  onClick={() => {
                    handleShow();
                    setIsClearingCart(true);
                  }}
                >
                  Remove All
                </Link>
              )}
              <Link
                to="/Checkout"
                className="btn btnPrimary ms-auto"
                onClick={() => {
                  handleCartClose();
                  // dispatch(setIsReRenderBooking(!isRerendering));
                }}
              >
                CHECKOUT{" "}
                <i>
                  <Icon icon="ep:arrow-right" />
                </i>
              </Link>
            </div>
          </Dropdown.Menu>
        }
      </Dropdown>
      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default CartDropdown;
