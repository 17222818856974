import React, { useEffect } from "react";
import { isAndroid, isIOS, isMacOs, isWindows } from "react-device-detect";

const Download = () => {
  useEffect(() => {
    if (isAndroid || isWindows) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.os.jinocustomer";
    } else if (isIOS || isMacOs) {
      window.location.href =
        "https://apps.apple.com/us/app/jino-on-demand-car-services/id1462862177";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.os.jinocustomer";
    }
  }, []);
  return <div />;
};

export default Download;
