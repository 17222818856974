import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Numbers = () => {
  return (
    <section className="number">
      <Container>
          <div className="titleBar">
            <span></span><img src="./images/logoIcon.svg" alt="" />
          </div>
          <Row>
            <Col lg={4}>
              <div className="text-center">
                <div className="img-holder">
                <img src="./images/shapeNumber.svg" alt="" />
                <span>1 MILLION +​</span>
                </div>
                <h3>ORDERS​</h3>
                <p>FOR THE LAST 3 YEARS​</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center">
                <div className="img-holder">
                <img src="./images/shapeNumber.svg" alt="" />
                <span>60.000+​​</span>
                </div>
                <h3>USERS</h3>
                <p>FOR THE LAST 3 YEARS​</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center">
                <div className="img-holder">
                <img src="./images/shapeNumber.svg" alt="" />
                <span>63%​​</span>
                </div>
                <h3>RETURNING USERS​</h3>
                <p>FOR THE LAST 3 YEARS​</p>
              </div>
            </Col>
          </Row>
          </Container>
        </section>
  )
}

export default Numbers