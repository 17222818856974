import { createSlice } from "@reduxjs/toolkit";

export interface userService {
  category: any;
  carType: any;
  service: any;
}

const initialState: userService = {
  category: null,
  carType: null,
  service: null,
};

export const guestUserServiceSlice = createSlice({
  name: "guestUserService",
  initialState,
  reducers: {
    setGuestSelectedUser: (state, action) => {
      state.carType = action?.payload?.carType;
      state.category = action?.payload?.category;
      state.service = action?.payload?.service;
      //
    },
  },
});

export const { setGuestSelectedUser } = guestUserServiceSlice.actions;

export default guestUserServiceSlice.reducer;
