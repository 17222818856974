import React from 'react'
import { Container } from 'react-bootstrap'
import ServicesSlider from './ServicesSlider'

const ServicesProfile = () => {
  return (
    <section className='services-profile'>
      <Container>
        <h2>Our Services </h2>
        <ServicesSlider/>
      </Container>
    </section>
  )
}

export default ServicesProfile