import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Button, Col, Row } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RemoveModal from "./RemoveModal";
import { Booking } from "../../features/bookingSlice";
import { Service } from "../../Types/services.interface";
import { Category } from "../../Types/category.interface";

interface Order {
  orders: Booking[];
  handleSelectItem: (order: Booking) => void;
  selectedItem: Booking | null;
  onDelete: (orderId: any) => void;
  services: Service[];
  categories: Category[];
}

const OrderDetail: React.FC<Order> = ({
  orders,
  handleSelectItem,
  selectedItem,
  onDelete,
  categories,
  services,
}) => {
  const dispatch = useDispatch();
  const [activeOrderId, setActiveOrderId] = useState<number | null>(null);
  const [activeServiceId, setActiveServiceId] = useState<number | null>(null);
  const [deleteId, setDeleteId] = useState("");
  // const[orderCheckout,setOrderCheckout] = useState<any>(null)

  const navigate = useNavigate();

  // useEffect(()=>{
  // dispatch(setIsReRenderBooking(true))

  // },[])
  //

  const handleDeleteOrder = () => {
    onDelete([deleteId]);
  };
  const toggleCardDetail = (index: number) => {
    if (activeOrderId === index) {
      setActiveOrderId(null);
    } else {
      setActiveOrderId(index);
    }
  };
  const toggleServiceDetail = (index: number) => {
    if (activeServiceId === index) {
      setActiveServiceId(null);
    } else {
      setActiveServiceId(index);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="orderDetailModal">
      {orders?.slice(0, orders?.length)?.map((order, index: number) => {
        const detail = order.booking_details[0];
        const category = categories.find(
          (cat) =>
            cat.uuid === detail.service_config?.service?.service_category_id
        );
        const service = services.find(
          (cat) => cat.uuid === detail.service_config?.service?.uuid
        );
        return (
          <div
            className={`orderContent
        ${activeOrderId === order.id ? "active" : ""}
        ${selectedItem?.id === order.id ? "selected-item" : ""}
        `}
            onClick={() => {
              handleSelectItem(order);
            }}
          >
            <div className="orderContentBox">
              <div className="mediaorderCard">
                <div className="icon d-none d-md-inline-block">
                  {/* <img src='/images/carWash.svg' alt='' /> */}
                  <img
                    src={category?.category_logo || "/images/carWash.svg"}
                    alt=""
                  />
                </div>
                <div className="media-body">
                  <h4>
                    <i className="d-md-none d-inline-block">
                      {" "}
                      {/* <img src='/images/carWash.svg' alt='' /> */}
                      <img
                        src={category?.category_logo || "/images/carWash.svg"}
                        alt=""
                      />
                    </i>
                    {detail?.service_config?.service?.name}
                  </h4>
                  <div className="address">
                    <div className="iconLabel">
                      <Icon icon="system-uicons:location" />
                    </div>
                    <span>{`${detail.customer_address?.label} ${detail.customer_address?.area}`}</span>
                  </div>
                  <div className="address">
                    <div className="iconLabel">
                      <Icon icon="clarity:car-line" />
                    </div>
                    <span>{`${detail.customer_vehicle?.model?.brand?.name} ${detail.customer_vehicle?.model?.name} - ${detail.customer_vehicle?.plate_no}`}</span>
                  </div>
                  <div className="price">
                    <span>AED {detail.price}</span>
                  </div>
                </div>
                <div className="action">
                  {/* 
                <button 
                  className='btn-remove'
                  onClick={() => {
                    onDelete(order?.bookingDetailsId);
                  }}>
                  <Icon icon='mdi:delete-outline' />
                </button> */}

                  <Button
                    className="btn-remove"
                    variant="primary"
                    onClick={() => {
                      handleShow();
                      setDeleteId(detail.uuid);
                    }}
                  >
                    <Icon icon="mdi:delete-outline" />
                  </Button>

                  <RemoveModal
                    show={show}
                    onHide={handleClose}
                    onDelete={handleDeleteOrder}
                    order={selectedItem}
                  />

                  <button
                    className={`cta-detail ${
                      activeOrderId === index ? "active" : ""
                    }`}
                    onClick={() => toggleCardDetail(index)}
                  >
                    Details{" "}
                    <i>
                      <Icon icon="iconamoon:arrow-down-2-light" />
                    </i>
                  </button>
                </div>
              </div>
              <div
                className={`collapseContent ${
                  activeOrderId === index ? "show" : "hide"
                }`}
              >
                <div className="titleContent">
                  <h4>Service</h4>
                </div>
                <div className="serviceInfo">
                  <div className="media">
                    <div className="icon">
                      {/* <img src='/images/carWash.svg' alt='' /> */}
                      <img
                        className="ordericon"
                        src={category?.category_logo ?? "/images/carWash.svg"}
                        alt=""
                      />
                    </div>
                    <div className="media-body">
                      {detail?.service_config?.service?.name}
                    </div>
                    <div className="cta_info">
                      <div className="price">
                        <span>AED {detail?.price}</span>
                      </div>
                      <button
                        className={`cta-desc ${
                          activeServiceId === index ? "active" : ""
                        }`}
                        onClick={() => toggleServiceDetail(index)}
                      >
                        <i>
                          <Icon icon="iconamoon:arrow-down-2-light" />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`infoDesc ${
                      activeServiceId === index ? "show" : "hide"
                    }`}
                  >
                    <h5>Description</h5>
                    <div
                      style={{
                        height: "168px",
                        overflowY: "auto",
                        padding: "12px",
                        paddingLeft: "8px",
                      }}
                    >
                      <Row className="g-0">
                        <Col lg={8}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: service?.description || "", //not receiving description
                            }}
                          ></div>
                        </Col>
                        <Col lg={4}>
                          <div className="imgHolder">
                            <img
                              src={
                                service?.images?.[0] ||
                                "https://assets.jino.ae/assets/services/6f0d7e47-a8b0-43f6-ad19-c4664ac64566.jpg"
                              } //not receiving service image
                              alt=""
                              className="w-100"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="titleContent">
                  <h4>Vehicle</h4>
                </div>
                <div className="vehicleinfo">
                  <div className="icon">
                    <img
                      src={
                        detail.customer_vehicle?.model?.brand?.image ||
                        "images/placehldrGroup.svg"
                      }
                      alt=""
                      width={24}
                    />
                    {/* <img src="/images/honda.svg"  alt="" /> */}
                  </div>
                  <div className="media-body">
                    <h3>{`${detail.customer_vehicle?.model?.brand?.name} ${detail.customer_vehicle?.model?.name}`}</h3>
                    <p>{detail.customer_vehicle?.plate_no}</p>
                  </div>
                  <div className="color">
                    <div className="name">
                      <span>Color</span>
                    </div>
                    <span
                      className="v-color "
                      style={{
                        background: `${detail.customer_vehicle?.color}`,
                      }}
                    ></span>
                  </div>
                </div>
                <div className="titleContent">
                  <h4>Location & Schedule</h4>
                  <button className="btn-edit ms-auto">
                    {/* <Icon
                      icon="ic:outline-edit"
                      onClick={() => {
                        navigate("/editlocation", {
                          state: {
                            forcedStep: 3,
                            selectedOrders: selectedItem,
                          },
                        });
                      }}
                    /> */}
                  </button>
                </div>
                <div className="addressInfo">
                  <div className="icon">
                    <Icon icon="system-uicons:location" />
                  </div>
                  <div className="media-body">
                    <h4>{detail.customer_address?.label}</h4>
                    <p>
                      {/* Topgolf - Emirates Hills 2 - Dubai - United Arab Emirates */}
                      {detail.customer_address?.area}
                    </p>
                  </div>
                </div>
                <div className="footerCollapseContent">
                  <div className="media-body">Selected Schedule</div>
                  <div className="dateTime">
                    <div className="date">
                      <i>
                        <Icon icon="bx:calendar" />
                      </i>{" "}
                      {detail.bookingAt.split("T")[0]}
                    </div>
                    <div className="time">
                      <i>
                        <Icon icon="mingcute:time-line" />
                      </i>{" "}
                      {detail?.start_time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderDetail;
