import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface UserInterface {
  uuid: string;
  phone_number: string;
  email?: string;
  first_name: string;
  last_name: string;
  address?: object;
  gender?: string;
  date_of_birth?: string;
  approved?: string;
  referral_code?: string;
  registration_referral_code?: string;
  registration_referral_used?: boolean;
  redeem_referral?: boolean;
  redeem_referral_expriy?: Date;
  redeem_referral_count?: number;
  app_version?: string;
  is_notification_enabled?: boolean;
  device_type?: string;
  notification_token?: string;
  profile_image?: string;
  status?: boolean;
  reciever_expiry?: Date;
  activity_date?: Date;
  last_order_date?: Date;
  oil_changed_at?: Date;
  sender_expiry?: Date;
  referral_sent: number,
      referral_credit: number,
}

interface User {
  user: UserInterface;
}

const initialState: User = {
  user: {
    uuid: '',
    phone_number: '',
    email: '',
    first_name: '',
    last_name: '',
    address: {},
    gender: '',
    date_of_birth: '',
    approved: '',
    referral_code: '',
    registration_referral_code: '',
    registration_referral_used: false,
    redeem_referral: false,
    redeem_referral_expriy: undefined,
    redeem_referral_count: 0,
    app_version: '',
    is_notification_enabled: true,
    device_type: '',
    notification_token: '',
    profile_image: '',
    referral_credit: 0,
    referral_sent: 0,
    status: true,
    reciever_expiry: undefined,
    activity_date: undefined,
    last_order_date: undefined,
    oil_changed_at: undefined,
    sender_expiry: undefined,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: User, action: PayloadAction<UserInterface>) => {
      state.user = action.payload;
    },
    setUserField: (
      state: User,
      action: PayloadAction<Partial<UserInterface>>,
    ) => {
      state.user = {...state.user, ...action.payload};
    },
    resetUserDetails: (state: User) => {
      state.user = initialState.user;
    }
  },
});

export const {setUser, setUserField, resetUserDetails} = userSlice.actions;
export const userSelector = (state: RootState) => state.user.user;
export default userSlice.reducer;
