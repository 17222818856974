import ContainerMain from "../../Components/layouts/main/ContainerMain";
import WhyJoinCompany from "./components/WhyJoinCompany";
import ServicesProfile from "./components/ServicesProfile";
import Numbers from "./components/Numbers";
import RegionalAmbitions from "./components/RegionalAmbitions";
import PartnershipBenefits from "./components/PartnershipBenefits";
import OurPartners from "./components/OurPartners";
import OurClients from "./components/OurClients";
import PartnerShipCallaboration from "./components/PartnerShipCallaboration";
import Footer from "../../Components/footer/Footer";
import ContactUs from "../About/components/ContactUs";

const CompanyProfile = () => {
  return (
    <ContainerMain>
      <WhyJoinCompany />
      <ServicesProfile />
      <Numbers />
      <RegionalAmbitions />
      <PartnershipBenefits />
      <OurPartners />
      <OurClients />
      <PartnerShipCallaboration />
      <ContactUs />
      <Footer />
    </ContainerMain>
  );
};

export default CompanyProfile;
