import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import LoadingIndicator from "../../loader/loader";

const Otp = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const [time, setTime] = useState(90); // Initial time in seconds (1:30)
  const [resentOtp, setResentOtp] = useState(false);
  const otpFieldsRef = useRef<HTMLInputElement[]>([]);
  const timerRef: any = useRef(null);

  // const inputRefs = useRef<HTMLInputElement[]>([]);
  const runTimer = () => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerRef.current); // Clear the previous timer
          setResentOtp(true);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);
  };

  const resendOtp = () => {
    otpFieldsRef.current[0]?.focus();
    props.reSignIn();
    setResentOtp(false);
    setTime(90);
    runTimer();
    setOtp(Array(6).fill(""));
  };

  useEffect(() => {
    if (!props.show) return;
    setResentOtp(false);
    setTime(90);
    runTimer();
    if (props.show) {
      setTimeout(() => {
        otpFieldsRef.current[0]?.focus();
      }, 1000); // Add a small delay (100 milliseconds) before setting focus
    }
    setOtp(Array(6).fill(""));
  }, [props.show]);

  useEffect(() => {
    if (time !== 0) return;
    setResentOtp(true);
  }, [time]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleSubmit = (otpTemp: string[]) => {
    // Handle OTP verification logic here
    props.verification(otpTemp.join(""));
    // props.verification("554477");
  };

  const exitFunction = () => {
    setOtp(Array(6).fill(null)); // Clear OTP values
    setResentOtp(true);
    clearInterval(timerRef.current);
  };

  const handleOtpChange = (index: any, value: any) => {
    if (value.length > 1) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value.length === 1 && index < otp.length - 1) {
      otpFieldsRef.current[index + 1].focus();
    }
    const isFilled: any = newOtp.every((val) => val !== "");
    if (isFilled) {
      handleSubmit(newOtp);
    }
  };

  const handleKeyDown = (index: any) => {
    if (otp[index] !== "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    } else if (index > 0) {
      otpFieldsRef.current[index - 1].focus();
    }
  };
  // const handleKeyDown = (
  //   event: React.KeyboardEvent<HTMLInputElement>,
  //   index: number,
  // ) => {
  //   if (event.key === "Backspace") {
  //     if (!otp[index] && index > 0) {
  //       const newOtp = [...otp];
  //       newOtp[index - 1] = "";
  //       setOtp(newOtp);
  //       inputRefs.current[index - 1]?.focus();
  //     } else if (!otp[index] && index === 0) {
  //       setOtp((oldOtp) => [
  //         ...oldOtp.slice(0, index),
  //         ...oldOtp.slice(index + 1),
  //       ]);
  //     }
  //   }
  // };

  //previous Handler OtpChange
  // const handleOtpChange = (index: number, value: string) => {
  //   const newOtp = [...otp];
  //   newOtp[index] = value;
  //   setOtp(newOtp);

  //   if (index === newOtp.length - 1 && value !== "") {
  //     const otpTemp = [...otp];
  //     otpTemp[otpTemp.length - 1] = value;
  //     handleSubmit(otpTemp);
  //   } else {
  //     if (value !== "") {
  //       inputRefs.current[index + 1].focus();
  //     }
  //   }
  // const [lastFocusedIndex, setLastFocusedIndex] = useState<number | null>(null);
  // const handleFocus = (index: number) => {
  //   setLastFocusedIndex(index);
  // };

  // const handleOnChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   index: number,
  // ) => {
  //   const newValue = event.target.value;
  //
  //   if (/^\d*$/.test(newValue) && newValue.length <= 1) {
  //     const newOtp = [...otp];
  //     newOtp[index] = newValue;
  //     setOtp(newOtp);

  //     if (newValue !== "" && index < 5) {
  //       inputRefs.current[index + 1]?.focus();
  //     } else if (newValue === "" && index > 0) {
  //       if (newOtp[index - 1] !== "") {
  //         inputRefs.current[index - 1]?.focus();
  //       } else if (lastFocusedIndex !== null) {
  //         inputRefs.current[lastFocusedIndex]?.focus();
  //       }
  //     } else if (newValue === "") {
  //       if (lastFocusedIndex !== null) {
  //         inputRefs.current[lastFocusedIndex]?.focus();
  //       }
  //     }
  //     const isFilled = newOtp.every((val) => val !== "");
  //     if (isFilled) {
  //       handleSubmit(newOtp);
  //     }
  //     if (newValue !== "") {
  //       setLastFocusedIndex(index);
  //     }
  //   }
  // };

  // const handleKeyDown = (
  //   event: React.KeyboardEvent<HTMLInputElement>,
  //   index: number,
  // ) => {
  //   if (event.key === "Backspace") {
  //     event.preventDefault();

  //     const newOtp = [...otp];
  //     newOtp[index] = "";
  //     setOtp(newOtp);

  //     if (index > 0) {
  //       inputRefs.current[index - 1]?.focus();
  //     }
  //   }
  // };
  // const resetOtp = () => {
  //   setOtp(Array(6).fill(""));
  //   otpFieldsRef.current.forEach((ref) => {
  //     if (ref) {
  //       ref.value = "";
  //     }
  //   });
  //   otpFieldsRef.current[0]?.focus();
  // };

  // useEffect(() => {
  //   if (!props.show) return;
  //   setResentOtp(false); // Clear previous timer
  //   resetTimer()
  //   return () => {
  //     clearInterval(timerRef.current); // Clear the timer when the component unmounts
  //   };
  // }, [props.show]);

  // useEffect(() => {
  //   if (time !== 0) return;
  //   setResentOtp(true);
  // }, [time]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="customModal"
      onExit={exitFunction}
    >
      <Modal.Body>
        <Button onClick={props.onHide} variant="btnIcon">
          <Icon icon="akar-icons:cross" />
        </Button>
        <div className="form-card">
          <img src="/images/amico.png" alt="" className="mb-4" />
          <h3>One-Time Password</h3>
          <p>Enter OTP to verify</p>
          <Form className="optFields" onSubmit={(e) => e.preventDefault()}>
            {[...Array(6)].map((_, index) => (
              <Form.Group key={index} controlId={`otp${index}`}>
                <Form.Control
                  type="text"
                  maxLength={1}
                  value={otp[index] || ""}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      handleKeyDown(index);
                    }
                  }}
                  ref={(ref: any) => {
                    otpFieldsRef.current[index] = ref;
                  }}
                />
              </Form.Group>
            ))}
          </Form>
          {!resentOtp && (
            <div className="note">
              Code will resend in <strong>{formatTime(time)}</strong>
              <br />
            </div>
          )}
          {resentOtp && (
            <div
              className="note"
              style={{ cursor: "pointer" }}
              onClick={resendOtp}
            >
              Resend OTP
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Otp;
