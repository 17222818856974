import React from 'react'
import { Link } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Header from '../../../Components/Header/Header';
const HeroMain = () => {
  return (
    <div className='secondaryHeader'>
      <div className="headerWrap">
        <Header />
      </div>
      
        <div className="card-hero">
      <Container>
          <div className="title">
            <span>Discover Jino</span>
            <h2>Your Partner in Professional Car Care</h2>
            <Link to="#">Book Your Wash Now</Link>
          </div>
      </Container>
        </div>
    </div>
  )
}

export default HeroMain