import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth.slice";
import bookingReducer from "../features/bookingSlice";
import locationReducer from "../features/locationSlice";
import applicationSettings from "../features/setting.slice";
import guestUserService from "../features/guestUserServiceSlice";
import serviceSlice from "../features/service.slice";
import categoriesSlice from "../features/categories.slice";
import userSlice from "../features/user.slice";
import vehicleSlice from "../features/vehicle.slice";
import promotionSlice from "../features/promotion.slice";
import locationSlice from "../features/location.slice";
import brandSlice from "../features/brand.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    order: bookingReducer,
    setting: applicationSettings,
    guestUserService: guestUserService,
    service_config: serviceSlice,
    categories: categoriesSlice,
    user: userSlice,
    vehicle: vehicleSlice,
    promotion: promotionSlice,
    location: locationSlice,
    brand: brandSlice
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
