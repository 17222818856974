import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Promotion } from "./promotion.slice";
import { RootState } from "../store";
import { ServiceConfig } from "./service.slice";
import { CustomerVehiclesAttributes } from "./vehicle.slice";
import { CustomerAddress } from "./location.slice";
import { UserInterface } from "./user.slice";

export interface Days {
    name: string;
    uuid: string;
}

export interface Timeslots {
    start_time: string;
    end_time: string;
    end_original: string;
}

export interface BookingDetails {
    uuid: string;
    customer_vehicle_id: string;
    booking_id: string;
    customer_address_id: string;
    package_config_id: string | null;
    service_config_id: string;
    driver_id: string;
    promotion_id: string | null;
    type: string;
    price: number;
    discount_value: number | null;
    bookingAt: string;
    start_time: string;
    end_time: string;
    status: boolean;
    jsonDump: any;
    day_id: string;
    cancel_reason: string | null;
    referral_code: string | null;
    comment: string | null;
    review: string | null;
    over_all_rating: number | null;
    replacement_reason: string | null;
    modified_by: string | null;
    estimated_arrival: string | null;
    estimated_arrival_km: string | null;
    slot_selected_at: Date | null;
    reordered_from: string | null;
    createdAt: string;
    updatedAt: string;
    customer_package_service_id: string | null;
    booking_status_id: number;
    service_config: ServiceConfig | null;
    package_config: any | null;
    driver: {
        uuid: string;
        first_name: string;
        last_name: string;
        notification_token: string | null;
        vendor_id: string;
    }
    customer_vehicle: CustomerVehiclesAttributes | null;
    customer_address: CustomerAddress | null;
    customer_package_service: any | null;
    promotion: Promotion | null;
    booking_status: BookingStatus;
    reorder_eligible: boolean;
    upsells: Array<string>
}

interface BookingStatus {
    int_id: number;
    name: string;
    status: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface Booking {
    uuid: string;
    id?: number;
    customer_id: string;
    amount: string;
    vat: string;
    status: boolean;
    payment_type: string;
    appVersion: string | null;
    transaction_ref: string;
    jsonDump: any;
    type: string;
    paid: boolean;
    paid_at: string | null;
    comment: string;
    message: string | null;
    createdAt: string;
    updatedAt: string;
    booking_status_id: number;
    booking_status?: BookingStatus;
    customer?: UserInterface;
    booking_details: BookingDetails[];
    details: Array<any>,
}

interface OrderState {
    days: Days[];
    timeSlots: Timeslots[];
    booking: Booking;
    selected: Booking | null,
    bookings: Booking[];
}

export const orderIntitalState: OrderState = {
    days: [],
    timeSlots: [],
    booking: {
        uuid: "",
        customer_id: "",
        amount: "",
        vat: "",
        status: true,
        payment_type: "",
        appVersion: null,
        transaction_ref: "",
        jsonDump: null,
        type: "",
        paid: false,
        paid_at: null,
        comment: "",
        message: null,
        createdAt: "",
        updatedAt: "",
        booking_status_id: 1,
        booking_details: [
        ],
        details: [],
    },
    selected: null,
    bookings: [],
}

const orderSlice = createSlice({
    name: 'order',
    initialState: orderIntitalState,
    reducers: {
        setDays: (state: OrderState, action: PayloadAction<Days[]>) => {
            state.days = action.payload
        },
        setBookingState: (state: OrderState, action: PayloadAction<Booking>) => { 
            state.booking = action.payload
        },
        setSelectedBooking: (state: OrderState, action: PayloadAction<Booking | null>) => { 
            state.selected = action.payload
        },
        setSlots: (state: OrderState, action: PayloadAction<Timeslots[]>) => {
            state.timeSlots = action.payload
        },
        setBookingFields: (state: OrderState, action: PayloadAction<Partial<Booking>>) => {
            state.booking = { ...state.booking, ...action.payload }
        },
        setBookingDetailsFields: (state: OrderState, action: PayloadAction<Partial<BookingDetails>>) => {
            state.booking.booking_details[0] = { ...state.booking.booking_details[0], ...action.payload }
        },
        resetBooking: (state: OrderState) => {
            state.booking = orderIntitalState.booking;
            // state.booking.booking_details = [];
            state.timeSlots = orderIntitalState.timeSlots;
        },
        setBookings: (state: OrderState, action: PayloadAction<Booking[]>) => {
            state.bookings = action.payload
        }
    }
});

export const { setDays, resetBooking, setSlots, setBookingFields, setBookingDetailsFields, setBookings, setBookingState, setSelectedBooking } = orderSlice.actions
export const daysSelector = (state: RootState) => state.order.days;
export const slotsSelector = (state: RootState) => state.order.timeSlots;
export const bookingSelector = (state: RootState) => state.order.booking;
export const selectedBookingSelector = (state: RootState) => state.order.selected;
export const bookingsSelector = (state: RootState) => state.order.bookings;
export const bookingDetailsSelector = (state: RootState) => state.order.booking.booking_details[0];

export default orderSlice.reducer