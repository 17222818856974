import React from 'react';

interface StepCounterProps {
    currentStep: number;
    totalSteps: number;
}

const StepCounter: React.FC<StepCounterProps> = ({ currentStep, totalSteps }) => {
    const renderSteps = (): React.ReactNodeArray => {
        let steps: React.ReactNodeArray = [];

        for (let i = 1; i <= totalSteps; i++) {
            const isActive = i <= currentStep;
            const stepClassName = isActive ? 'active' : '';

            steps = steps.concat(<li key={i} className={stepClassName}><span></span></li>);
        }

        return steps;
    };

    return (
        <div className='counterWizard'>
            <div className='steps'>Step <span>{currentStep.toString().padStart(1 )}</span></div>
            <ul className='list-unstyled stepers'>
                {renderSteps()}
            </ul>
        </div>
    );
};

export default StepCounter;
