import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import StepWizard from "../../Components/services/StepWizard";
const Services = () => {
  const [editData, setEditData] = useState(null);
  const location = useLocation();
  useEffect(() => {
    setEditData(location?.state);
  }, [location.state]);

  return (
    <>
      <section className="wrapperMain homeMain postLoginBG">
        <Container>
          <Row>
            <Col lg={7}>
              <div className="heroText">
                <span>CAR SERVICE</span>
                <h1>
                  UAE’s best car service app with predictive maintenance
                  Solutions
                </h1>
                <p>
                  Order all car services at your doorstep & Connect your vehicle
                  with JINO app to manage all car services from a single
                  platform. Discover our predictive maintenance solution for the
                  new age of automation in the industry.
                </p>
                <div className="imgHolder">
                  <img
                    className="carpool-img"
                    src="/images/Carpool.svg"
                    alt=""
                  />
                  <img
                    className="arrowImg"
                    src="/images/arrowVector.svg"
                    alt=""
                  />
                </div>
                <h4>Want to try our services?</h4>
                <h3>Fill this form to experience our services</h3>
              </div>
            </Col>
            <Col lg={5}>
              <StepWizard editData={editData} />
            </Col>
          </Row>
        </Container>
        <div className="text-center">
          <img src="/images/circle.png" alt="" className="circlebg" />
        </div>
      </section>
    </>
  );
};

export default Services;
