import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const PartnerShipCallaboration = () => {
  return (
    <section className='partnership-collaborate'>
      <Container>
        <Row>
          <Col lg={6}>
           <h2>Partnership​ Collaboration</h2>
           <div className="content">
            <div className="inner-content">
            <p>JINO provides marketing collaboration to its partners through the following activities:​</p>
            <ul>
              <li> <i><img src="./images/arrow.svg" alt="" /></i> <span>VEHICLES CO-BRANDING​</span></li>
              <li> <i><img src="./images/arrow.svg" alt="" /></i> <span>MOBILE APP & WEBSITE CO-BRANDING​​</span></li>
              <li> <i><img src="./images/arrow.svg" alt="" /></i> <span>SOCIAL MEDIA POSTS & ADVERTISMENTS CO-BRANDING​​</span></li>
              <li> <i><img src="./images/arrow.svg" alt="" /></i> <span>BILLBOARDS ADVERTISMENTS CO-BRANDING​​</span></li>
              <li><i><img src="./images/arrow.svg" alt="" /></i>  <span>EQUIPMENT AND COLLATERAL ITEMS CO-BRANDING</span></li>
            </ul>
            </div>
           </div>
          </Col>
          <Col lg={6}>
            <div className="img-holder">
              <img src="./images/img-discover.png" alt="" className='img-fluid' />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PartnerShipCallaboration