import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Promotion {
    uuid: string;
    name: string;
    service_config_ids: string[];
    service_names: string;
    package_id: string | null;
    discount_percentage: string;
    start_date: string;
    end_date: string;
    code: string | null;
    status: boolean;
    level_id: string;
    level_type: string;
    auto_apply: boolean;
    image: string;
    show_homepage: boolean;
    color: string;
    color_name: string | null;
    description: string;
    color_secondary: string;
    user_limit: number;
    deprecated: boolean;
    code_limit: string | null;
    polygon: string | null;
    specific_location: string | null;
    automate_notifications: string | null;
    notification_title: string | null;
    notification_body: string | null;
    createdAt: string;
    updatedAt: string;
    linked_id: string | null;
    Services?: any;
    used: boolean;
    is_applicable: boolean;
    tag_line: string;
}

interface PromotionState {
    promotion: Promotion | null;
    promotions: Promotion[],
}

const initialState: PromotionState = {
    promotion: null,
    promotions: []
}

const promotionSlice = createSlice({
    name: "promotion",
    initialState,
    reducers: {
        setPromotion: (state: PromotionState, action: PayloadAction<Promotion>) => {
            state.promotion = action.payload;
        },
        resetPromotion: (state: PromotionState) => {
            state.promotion = null;
        },
        setPromotions: (state: PromotionState, action: PayloadAction<Promotion[]>) => {
            state.promotions = action.payload;
        }
    }
});

export const { setPromotion, resetPromotion, setPromotions } = promotionSlice.actions;
export const promotionSelector = (state: RootState) => state.promotion.promotion;
export const promotionsSelector = (state: RootState) => state.promotion.promotions;

export default promotionSlice.reducer;