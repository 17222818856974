import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface AuthState {
  value: any;
  userData: any;
}

// Define the initial state using that type
const initialState: AuthState = {
  value: null,
  userData: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      
      state.value = action.payload;
    },
    loadUser: (state, action) => {
      state.userData = action.payload;
    },
    removeUser: (state) => {
      state.userData = null;
    },
    setAuthUserData: (state, action) => {
      state.userData = action.payload;
      sessionStorage.setItem("user", JSON.stringify(action.payload));

    }
  },
});

export const { setAuthUser, loadUser, removeUser, setAuthUserData } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const auth = (state: RootState) => state.auth.value;

export default authSlice.reducer;
