import { User } from "../Types/user.interface";
import { get, post, put } from "./api.service";

export const signinUser = async (phoneNumber: string) => {
  try {
    const res = await post("customer/login", { phone_number: phoneNumber });
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};

export const getUser = async () => {
  try {
    const res = await get("customer");
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};

export const uploadImage = async (file: any) => {
  const formData = new FormData();
  formData.append("images", file);

  try {
    const response = await fetch(
      "https://jinostagingapi.futuristic.agency/api/v1/upload/others",
      {
        method: "POST",
        body: formData,
      }
    );

    const data = await response.json();
    if (response.ok) {
      return { error: null, data: data.result[0] };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};

export const updateUser = async (data: User) => {
  try {
    const res = await put("customer", {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      // phone_number: data.phoneNumber,
      profile_image: data.imagePath,
    });
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
  }
};
