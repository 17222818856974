import { get, post, put } from "./api.service";

export const fetchSavedLocations = async () => {
  try {
    const res = await get(`customer/address?status=true`);
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
    //
  }
};

export const createLocation = async (requestBody: any) => {
  try {
    delete requestBody.uuid;
    const res = await post(`customer/address`, requestBody);
    if (res?.status) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
    //
  }
};
export const editSavedLocation = async (requestBody: any) => {
  const res = await put(`customer/address`, requestBody);
  //
  try {
    if (res?.status || res?.result) {
      return { error: null, data: res };
    }
  } catch (error) {
    return { error: error, data: null };
    //
  }
};

export const fetchNearestLocation = async (lat: number, lng: number) => {
  try {
    const res = await get(
      `customer/address/nearest?lat=${25.2048}&lng==${55.2708}`
    );
    if (res?.status) {
      return { error: null, data: res.result };
    }
  } catch (error) {
    return { error: error, data: null };
    //
  }
};
