import React from "react";
import { Triangle, Bars, Grid } from "react-loader-spinner";

const LoadingIndicator = () => {
  return (
    <>
      <div className="loader-holder" style={styles.overlay}></div>
      <div className="loader" style={styles.loader}>
        <Triangle color="#04acda" height={100} width={100} />
      </div>
    </>
  );
};

export default LoadingIndicator;

const styles = {
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 999999,
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "#000",
    height: "100%",
    opacity: 0.6,
    position: "fixed",
    width: "100%",
    zIndex: 999998,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  },
};
