import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import StepWizard from "../../Components/post-login/StepWizard";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import SearchCard from "./form/SearchCard";
import ContainerMain from "../../Components/layouts/main/ContainerMain";

const PostLoginServices = () => {
  const [editData, setEditData] = useState(null);
  const [isGuestUser, setIsGuestUser] = useState(false);
  const guestService = useSelector(
    (state: RootState) => state?.guestUserService?.category
  );
  const user = useSelector((state: RootState) => state?.auth?.userData);

  const location = useLocation();
  useEffect(() => {
    setEditData(location?.state);
  }, [location.state]);

  useEffect(() => {
    if (user && guestService !== null) {
      setIsGuestUser(true);
    } else if (!user) {
      setIsGuestUser(false);
    }
  }, [user]);
  return (
    <>
      <ContainerMain>
        <section className={`wrapperMain homeMain`}>
          <Container fluid>
            <div className="heroText">
              <span>BOOK NOW</span>
              <h1>
                UAE's Leading Car Wash & Oil change solution at your doorstep
              </h1>
            </div>
          </Container>
          <div className="w-100 mt-xl-4 flex__1">
            <Container fluid className="flex__1">
              <Row className="flex_1">
                <Col xl={3} sm={6} xs={6} className="order-xl-1 order-2">
                  <div className="download">
                    <div className="title">
                      <span>Download</span>
                      <h4>JINO APP:</h4>
                    </div>
                    <ul>
                      <li>
                        <Link
                          to="https://play.google.com/store/apps/details?id=com.os.jinocustomer"
                          className="downloadLink"
                        >
                          <img src="./images/google-play-preview1.png" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://apps.apple.com/us/app/jino-on-demand-car-services/id1462862177"
                          className="downloadLink"
                        >
                          <img src="./images/ios-preview1.png" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://play.google.com/store/apps/details?id=com.os.jinocustomer"
                          className="downloadLink"
                        >
                          <img src="./images/gallery1.png" alt="" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xl={6} lg={12} className="order-xl-2 order-1">
                  <SearchCard />
                </Col>
                <Col xl={3} sm={6} xs={6} className="order-xl-3 order-3">
                  <div className="imgHolder">
                    <img src="./images/mobile1.png" alt="" />
                  </div>
                </Col>
              </Row>
            </Container>

            <div className="text-center">
              <img src="/images/circle.png" alt="" className="circlebg" />
            </div>
          </div>
        </section>
      </ContainerMain>
    </>
  );
};

export default PostLoginServices;
