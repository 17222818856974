import React from 'react'
import { Col, Row } from 'react-bootstrap'

const RegionalAmbitions = () => {
  return (
    <section className='regionalMain'>
      <Row className='g-0 align-items-center'>
        <Col lg="6">
          <h2>Our Regional <br /> Ambition​</h2>
      </Col>
      <Col lg="6">
      <div className="img-holder">
      <img src="./images/imgMobile.png" alt="" className='img-fluid'/>
      </div>
      </Col>
      </Row>
    </section>
  )
}

export default RegionalAmbitions