import React from "react";
import { Container } from "react-bootstrap";
import ContainerMain from "../../Components/layouts/main/ContainerMain";

const Policy = () => {
  return (
    <>
    
    <ContainerMain>
      <section className="wrapperMain homeMain">
        <Container className="z10">
          <h2 className="mainHeading">Privacy Policy</h2>
          <div className="policyCntainer">
            <h2>INFORMATION WE COLLECT</h2>
            <h3>New Permission: READ_SMS</h3>

            <p> To enhance your experience and streamline the process of reading One-Time Passwords (OTPs) for authentication, we have introduced the "READ_SMS" permission. This permission is necessary for reading OTPs sent to your device to improve user's overall experience with JINO. We assure you that the use of this permission is solely for the purpose of improving the security and user experience within JINO.
            </p>
            <h3>Information you provide to us :</h3>
            <p className="">
              We ask for and collect the following personal information about
              you when you use our Platform. This information is necessary for
              the adequate performance of the contract between you and us and to
              allow us to comply with our legal obligations. Without it, we may
              not be able to provide you with access to the Platform.
            </p>
            <div className="policyBox">
              <h3>Account Information : </h3>
              <p>
                When you sign up for an Account, we require certain information
                such as your first name, last name, phone number, email address,
                and date of birth.
              </p>
              <h3>Profile and Car Information : </h3>
              <p>
                To use certain features of the Platform (such as placing an
                order or booking any of the Services ), we may ask you to
                provide additional information, which may include your address,
                car details (including car plate number), phone number, and a
                profile picture.
              </p>
              <h3>Identity Verification Information :</h3>
              <p>
                To comply with applicable laws, and create a more trusted
                environment, we may request and collect identity verification
                information (such as images of your government issued ID (e.g.
                national id), passport or other authentication information. To
                learn more, contact our JINO customer service.
              </p>
              <h3>Payment Information : </h3>
              <p>
                To process any payments on the Platform (such as placing an
                order or booking any of the Services), we may require you to
                provide certain financial information (like credit card
                information) in order to facilitate the processing of payments.
              </p>
              <h3>Communications with JINO :</h3>
              <p>
                When you communicate with JINO or use the Platform to
                communicate preferences etc. we collect information about your
                communication and any information you choose to provide.
              </p>
            </div>
            <h3>Additional information you may choose to share :</h3>
            <p className="">
              You may choose to provide us with additional personal information
              in order to obtain a better user experience when using JINO. This
              additional information will be processed based on our discretion
              or when applicable, your consent.
            </p>
            <div className="policyBox">
              <h3>Additional Profile Information :</h3>
              <p>
                may choose to provide additional information as part of your
                JINO profile (such as gender, preferred language(s), city, and a
                personal description). Some of this information as indicated in
                your Account settings is part of your public profile page, and
                will be publicly visible to JINO third party service providers.
              </p>
              <h3>Personal Interests contacts, and other Information :</h3>
              <p>
                You may choose to provide the address of your current work,
                relatives, or other points of interest manually to access
                certain features of the Platform, like inviting them to use
                JINO.
              </p>
              <h3>Other Information : </h3>
              <p>
                You may otherwise choose to provide us information when you fill
                in a form, update or add information to your Account, respond to
                surveys, post to community forums, participate in promotions,
                communicate with our customer care team, share your experience
                or reviews with us or use other features of the Platform.
              </p>
            </div>
            <h3>Third Party Providers :</h3>
            <p className="">
              You may choose to provide or share information (such as
              preferences, service requests, national ID, other information)
              with third party providers to facilitate some services as prompted
              on the Platform.
            </p>
            <h3>Information we may collect automatically :</h3>
            <p className="">
              You may choose to provide or share information (such as
              preferences, service requests, national ID, other information)
              with third party providers to facilitate some services as prompted
              on the Platform.
            </p>
            <div className="policyBox">
              <p>
                When you use the Platform and the payment services, we
                automatically collect personal information about the services
                you use and how you use them. This information is necessary for
                the adequate performance of the contract between you and us, to
                enable us to comply with legal obligations and given our
                legitimate interest in being able to provide and improve the
                functionalities of the Platform and payment services.
              </p>
              <h3>Geo-location Information : </h3>
              <p>
                When you use certain features of the JINO Platform, we may
                collect information about your precise or approximate location
                as determined through data such as your IP address or mobile
                device’s GPS to offer you an improved user experience. Most
                mobile devices allow you to control or disable the use of
                location services for applications in the device’s settings
                menu. JINO may also collect this information even when you are
                not using the application if this connection is enabled through
                your settings or device permissions.
              </p>
              <h3>Usage Information :</h3>
              <p>
                We collect information about your interactions with the JINO
                Platform such as the pages or content you view, orders you have
                placed, and other actions on the Platform.
              </p>
              <h3>Log Data and Device Information : </h3>
              <p>
                . We automatically collect log data and device information when
                you access and use the Platform, even if you have not created a
                Account or logged in. That information includes, among other
                things: details about how you’ve used the Platform (including if
                you clicked on links to third party applications), IP address,
                access dates and times, hardware and software information,
                device information, device event information, unique
                identifiers, crash data, cookie data, and the pages you’ve
                viewed or engaged with before or after using the Platform.
              </p>
              <h3>Cookies and Similar Technologies :</h3>
              <p>
                We use cookies and other similar technologies when you use our
                platform, use our mobile app, or engage with our online ads or
                email communications. We may collect certain information by
                automated means using technologies such as cookies, web beacons,
                pixels, browser analysis tools, server logs, and mobile
                identifiers. In many cases the information we collect using
                cookies and other tools is only used in a non-identifiable
                without reference to personal information. For example, we may
                use information we collect to better understand website traffic
                patterns and to optimize users experience. In some cases we
                associate the information we collect using cookies and other
                technology with your personal information. Our business or
                technology partners may also use these tracking technologies on
                the Platform or engage others to track your behavior on our
                behalf.
              </p>
              <h3>Pixels and SDKs :</h3>
              <p>
                Third parties, including Facebook, may use cookies, web beacons,
                and other storage technologies to collect or receive information
                from our websites and elsewhere on the internet and use that
                information to provide measurement services and target ads. For
                third party apps, including Facebook, may collect or receive
                information from your app and other apps and use that
                information to provide measurement services and targeted ads.
                Users can opt-out of the collection and use of information for
                ad targeting by updating their Facebook account ad settings and
                by contacting our customer Service with a description of your
                request and validation information.
              </p>
              <h3>Do Not Track Signals :</h3>
              <p>
                While you may disable the usage of cookies through your browser
                settings, the JINO Platform currently does not respond to a “Do
                Not Track” signal in the HTTP header from your browser or mobile
                application due to lack of standardization regarding how that
                signal should be interpreted.
              </p>
              <h3>Payment Transaction Information :</h3>
              <p>
                JINO collects information related to your payment transactions
                through the JINO Platform, including the payment instrument
                used, date and time, payment amount, payment instrument
                expiration date and billing postcode, email address, credit or
                debit card information, your address and other related
                transaction details. This information is necessary for the
                adequate performance of the contract between you and JINO and to
                allow the provision of the payment services.
              </p>
            </div>
            <h3>Information We Collect from Third Parties :</h3>
            <div className="">
              <p>
                JINO may collect information, including personal information,
                that others provide about you when they use the Platform and the
                payment services, or obtain information from other sources and
                combine that with information we collect through the Platform
                and the payment services. We do not control, supervise or
                respond for how the third parties providing your information
                process your personal information, and any information request
                regarding the disclosure of your personal information to us
                should be directed to such third parties.
              </p>
              <h3>Third Party Services :</h3>
              <p>
                If you link, connect, or login to your Account with a third
                party service (e.g. Google, or Facebook), the third party
                service may send us information such as your registration,
                friends list, and profile information from that service. This
                information varies and is controlled by that service or as
                authorized by you via your privacy settings at that service.
              </p>
              <h3>Background Information :</h3>
              <p>
                We may obtain reports from public records as part of the user
                vetting procedures that may be undertaken from time to time. To
                the extent permitted by applicable laws and with your consent
                where required, we may obtain the local version of police or
                background checks. We may use your information, including your
                full name and date of birth, to obtain such reports.
              </p>
              <h3>Referrals :</h3>
              <p>
                If you are invited to JINO or have been referred to use our
                Services, the person who invited you may submit personal
                information about you such as your email address or other
                contact information.
              </p>
              <h3>Other Sources :</h3>
              <p>
                To the extent permitted by applicable law, we may receive
                additional information about you, such as demographic data or
                information to help detect fraud and safety issues, from third
                party service providers and/or partners, and combine it with
                information we have about you. For example, we may receive
                background check results (with your consent where required) or
                fraud warnings from service providers like identity verification
                services for our fraud prevention and risk assessment efforts.
                We may receive information about you and your activities on and
                off the Platform through partnerships, or about your experiences
                and interactions from our partner ad networks.
              </p>
            </div>
            <h3>ELIGIBILITY & PERMISSIONS :</h3>
            <h3>Children’s Data :</h3>
            <p className="">
              We do not knowingly, or intent on collecting any data from
              children under 18. Our websites and applications are not intended
              for use by children under the age of 18 years. We verify that to
              be eligible for usage of our websites and applications, users are
              required to be above the age of 18. If you believe that we are
              processing the personal information pertaining to a child
              inappropriately, we take this very seriously and urge you to
              contact us. To learn more, contact our JINO customer services
              team.
            </p>
            <h3>READ_SMS Permission</h3>

            <p>The recently added "READ_SMS" permission is specifically designed to read OTPs and enhance the user experience during the authentication process. We assure you that the SMS data collected is used solely for this purpose and is not shared, sold, or used for undisclosed features.</p>
            <h3>Users Eligibility :</h3>
            <p className="">
              All our users have willfully consented to the terms of our privacy
              policy, and therefore acknowledge our data collection, processing,
              and sharing procedures and terms. Features of our websites and
              applications are contingent of our data collection and sharing
              policies. Users may choose to opt out of specific data collection
              and sharing processes. To learn more, contact our JINO Customer
              service team.
            </p>
            <h3>
              RESPONDING TO LEGAL REQUESTS, PREVENTING HARM AND COMPLYING TO THE
              LAW
            </h3>
            <p className="">
              JINO may disclose your information, including personal
              information, to courts, law enforcement, governmental authorities,
              tax authorities, or authorized third parties, if and to the extent
              we are required or permitted to do so by law or if such disclosure
              is reasonably necessary:
            </p>
            <div className="policyBox">
              <p>to comply with our legal obligations</p>
              <p>
                to comply with legal process and to respond to claims asserted
                against JINO,
              </p>
              <p>
                to respond to valid requests relating to a criminal
                investigation or alleged or suspected illegal activity or any
                other activity that may expose us, you, or any other of our
                users to legal liability,
              </p>
              <p>
                to enforce and administer our Terms of Use, or (v) to protect
                the rights, property or personal safety of JINO, its partners,
                its franchisees, its licensees, its employees, its users, or
                members of the public.
              </p>
            </div>
            <p className="">
              These disclosures may be necessary to comply with our legal
              obligations, for the protection of your or another person’s vital
              interests or for the purposes of our or a third party’s legitimate
              interest in keeping the Platform secure, preventing harm or crime,
              enforcing or defending legal rights, facilitating the collection
              of taxes and prevention of tax fraud or preventing damage.
            </p>
            <h3>PRIVACY POLICY CHANGES AND UPDATES</h3>
            <p className="">
              JINO reserves the right to modify or update this Privacy Policy at
              any time. It is your responsibility to ensure that you are up to
              date with the recent provisions and terms of our Privacy Policy.
              The Privacy Policy if effective 5 days from the time it is
              published on our website or application. In the event that you
              disagree with the Privacy Policy, you reserve the right to cancel
              your account and inform our JINO customer service team of concerns
              or disagreement with the terms of the new Privacy Policy.
            </p>
          </div>
        </Container>
        <div className="text-center">
          <img src="/images/circle.png" alt="" className="circlebg" />
        </div>
      </section>
      </ContainerMain>
    </>
  );
};

export default Policy;
