import React from "react";
import Select, {
  components,
  SingleValueProps,
  OptionProps,
} from "react-select";

interface CustomSelectProps {
  options: any[];
  withIcon: boolean;
  value?: any;
  onChange?: (option: any) => void;
  placeholder?: string;
  noOptionMessage?: string;
  error?: string;
}

const customSingleValue = (props: SingleValueProps<any>) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {props.data.image && (
      <img
        src={props.data.image}
        alt={props.data.label}
        style={{ width: 20, height: 20, marginRight: 5 }}
      />
    )}
    <span>{props.data.label}</span>
  </div>
);

const customOption = (props: OptionProps<any>) => (
  <div
    {...props.innerProps}
    style={{ display: "flex", alignItems: "center", padding: 10 }}
  >
    {props.data.image && (
      <img
        src={props.data.image}
        alt={props.data.label}
        style={{ width: 20, height: 20, marginRight: 5 }}
      />
    )}
    <span>{props.data.label}</span>
  </div>
);

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  withIcon,
  value,
  onChange,
  placeholder,
  noOptionMessage,
  error,
}) => {
  return (
    <Select
      classNamePrefix="react-select"
      className={error ? "error" : ""}
      options={options}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      noOptionsMessage={() => noOptionMessage}
      components={{
        SingleValue: withIcon ? customSingleValue : components.SingleValue,
        Option: withIcon ? customOption : components.Option,
      }}
      isSearchable={true}
         />
  );
};

export default CustomSelect;
