import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const CounterMain = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col md={3} sm={6} className='mb-md-0 mb-4'>
            <div className="conterCard">
              <h4>836M+</h4>
              <span>CARS REGISTERED</span>
            </div>
          </Col>
            <Col md={3} sm={6}  className='mb-md-0 mb-4'>
            <div className="conterCard">
              <h4>738M</h4>
              <span>cars washed</span>
            </div>
          </Col>
            <Col md={3} sm={6} className='mb-md-0 mb-4'>
            <div className="conterCard">
              <h4>100M+</h4>
              <span>customers</span>
            </div>
          </Col>
            <Col md={3} sm={6} className='mb-md-0 mb-4'>
            <div className="conterCard">
              <h4>2022</h4>
              <span>established</span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CounterMain