import WhoWeAre from "./components/WhoWeAre";
import ContactUs from "./components/ContactUs";
import ContainerSecondary from "../../Components/layouts/main/ContainerSecondary";
import HeroMain from "./components/HeroMain";
import CounterMain from "./components/CounterMain";
import OurEstablishment from "./components/OurEstablishment";
import WhyChooseUs from "./components/WhyChooseUs";
// import Footer from "../../components/footer/Footer";

const AboutUs = () => {
  return (
    <>
      <ContainerSecondary>
        <HeroMain />
        <WhoWeAre />
        <CounterMain />
        <OurEstablishment />
        <WhyChooseUs />
        <ContactUs />
      </ContainerSecondary>
    </>
  );
};

export default AboutUs;
