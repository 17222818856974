import React from 'react';

const HrDivider = (props:any) => {
  const { data, innerRef, innerProps, selectProps } = props;
  const { options } = selectProps;

  const isBeforeDivider = options.findIndex((option: { isDivider: any; }) => option.isDivider) > options.indexOf(data);

  if (data.isDivider) {
    return (
      <hr ref={innerRef} {...innerProps}  />
    );
  }

  return (
    <div ref={innerRef} {...innerProps} className={isBeforeDivider ? 'history' : ''}>
      {data.label}
    </div>
  );
};

export default HrDivider;
