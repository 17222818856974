import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

export interface BrandInterface {
    createdAt: string;
    image: string;
    name: string;
    status: boolean;
    updatedAt: string;
    uuid: string;
}

interface VehicleTypeInterface {
    uuid: string;
    name: string;
    status: boolean;
    updatedAt: string;
    createdAt: string;
}

export interface ModelInterfact {
    brand: BrandInterface | undefined;
    brand_id: string;
    createdAt: string;
    image: string;
    name: string;
    status: boolean;
    updatedAt: string;
    uuid: string;
    vehicle_type: VehicleTypeInterface | undefined;
    vehicle_type_id: string;
}

interface Brand {
    brands: BrandInterface[];
    models: ModelInterfact[];
}

const initialState: Brand = {
    brands: [],
    models: [],
}

const brandSlice = createSlice({
    name: "brand",
    initialState,
    reducers: {
        setBrands: (state: Brand, action: PayloadAction<BrandInterface[]>) => {
            state.brands = action.payload;
        },
        setModels: (state: Brand, action: PayloadAction<ModelInterfact[]>) => {
            state.models = action.payload;
        }
    }
})

export const { setBrands, setModels } = brandSlice.actions;
export const brandsSelector = (state: RootState) => state.brand.brands
export const modelsSelector = (state: RootState) => state.brand.models
export default brandSlice.reducer;