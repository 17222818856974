import { Icon } from "@iconify/react";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Amplify from "aws-amplify";
import awsconfig from "../../../aws-exports";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../../features/auth.slice";
import { RootState } from "../../../store";
import { setGuestSelectedUser } from "../../../features/guestUserServiceSlice";
import { resetBooking } from "../../../features/bookingSlice";
import { resetUserDetails } from "../../../features/user.slice";
import {
  resetLocationState,
  resetSelectedAddress,
} from "../../../features/location.slice";
import { resetSelectedConfig } from "../../../features/service.slice";
import { resetVehicle } from "../../../features/vehicle.slice";

Amplify.configure(awsconfig);

interface UserDropdownProps {
  status: string;
  images: string[];
  menuItems: { icon: string; label: string; link: string }[];
}

const UserDropdown: React.FC<UserDropdownProps> = ({
  status,
  images,
  menuItems,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const user = useSelector((state: RootState) => state.auth.userData);

  if (status === "Offline") {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="user" id="dropdown-basic">
          <div className="avatar">
            <img src={images[1]} alt="" />
            <span className="status"></span>
          </div>

          <div className="name">{user?.first_name + " " + user?.last_name}</div>
        </Dropdown.Toggle>
      </Dropdown>
    );
  }

  const handleShowModal = () => {
    setShowModal(true);
    return;
  };
  const handleHideModal = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    Auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    dispatch(removeUser());
    dispatch(resetUserDetails());
    dispatch(resetSelectedAddress());
    dispatch(resetSelectedConfig());
    dispatch(resetVehicle());
    dispatch(resetLocationState());
    dispatch(resetBooking());
    dispatch(
      setGuestSelectedUser({
        category: null,
        carType: null,
        service: null,
      })
    );
  };

  return (
    <Dropdown show={showModal} onToggle={setShowModal}>
      <Dropdown.Toggle variant="user" id="dropdown-basic">
        <div className="avatar" onClick={handleShowModal}>
          <img src={user?.profile_image || images[0]} alt="" />
          <span className="status"></span>
        </div>
        <div className="name" onClick={handleShowModal}>
          {user?.first_name + " " + user?.last_name}
        </div>
      </Dropdown.Toggle>
      {showModal && (
        <Dropdown.Menu align="end">
          <div className="btn-user">
            <div className="avatar">
              <img src={user?.profile_image || images[0]} alt="" />
              <span className="status"></span>
            </div>
            <div className="name">
              {user?.first_name + " " + user?.last_name}
              <p className="userEmail" title={user?.email}>{user?.email}</p>
            </div>
          </div>
          <hr className="m-0 mt-2 -bs-gray" />
          <ul className="menuProfile">
            <li onClick={handleHideModal}>
              <Link to={"/Profile"}>
                <i>
                  <Icon icon={"mdi:user-outline"} />
                </i>
                <span>{"Profile"}</span>
              </Link>
            </li>
            <li>
              <Link to={"/"} onClick={handleLogout}>
                <i>
                  <Icon icon={"material-symbols:logout"} />
                </i>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default UserDropdown;
