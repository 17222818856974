import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const WhyJoinCompany = () => {
  return (
    <section className='whyJoinCompany'>
      <Container>
        <div className="titleBar">
          <span></span><img src="./images/logoIcon.svg" alt="" />
        </div>
        <h2>Why use Jino?​</h2>
        <div className="content">
          <Row>
            <Col lg={4} md={6}>
              <div className="item">
                <div className="icon">
                  <img src="./images/icon1.svg" alt="" />
                </div>
                <h3>Speedy Service</h3>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="item">
                <div className="icon">
                  <img src="./images/icon2.svg" alt="" />
                </div>
                <h3>Live Tracking​</h3>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="item">
                <div className="icon">
                  <img src="./images/icon3.svg" alt="" />
                </div>
                <h3>Professional Team​</h3>
              </div>
            </Col>
                <Col lg={4} md={6}>
              <div className="item">
                <div className="icon">
                  <img src="./images/icon4.svg" alt="" />
                </div>
                <h3>One stop for Car Wash & Oil change​</h3>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="item">
                <div className="icon">
                  <img src="./images/icon5.svg" alt="" />
                </div>
                <h3>Proactive scheduling​​</h3>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="item">
                <div className="icon">
                  <img src="./images/icon6.svg" alt="" />
                </div>
                <h3>Complementary preventive maintenance data feed​​</h3>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default WhyJoinCompany