import React, { useState } from "react";
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ServiceConfig,
  configsSelector,
  selectedConfigSelector,
  setSelectedConfig,
} from "../../../features/service.slice";
import {
  setBookingDetailsFields,
  setBookingFields,
} from "../../../features/bookingSlice";
import { settingSelector } from "../../../features/setting.slice";

interface SelectedItem {
  name: string;
}

const DropdownPopover: React.FC = () => {
  const dispatch = useDispatch();
  const configs = useSelector(configsSelector);
  const selected = useSelector(selectedConfigSelector);
  const settings = useSelector(settingSelector);

  const handleSelect = (service: ServiceConfig) => {
    const vat = (service.price * Number(settings.vat)) / 100;
    dispatch(setSelectedConfig(service));
    dispatch(
      setBookingDetailsFields({
        service_config_id: service.uuid,
        price: service.price,
        start_time: undefined,
        end_time: undefined,
      })
    );
    dispatch(
      setBookingFields({
        vat: vat.toString(),
        amount: service.price.toString(),
      })
    );
  };

  const popoverContent = (content: string, price: number) => (
    <Popover
      id="popover-basic"
      className="popoverWash"
      style={{ maxWidth: "540px" }}
    >
      <Popover.Body>
        <div className="titlePopup">
          <h4>Description</h4>
          <h4 className="price"> AED {price}</h4>
        </div>
        <p
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></p>
      </Popover.Body>
    </Popover>
  );

  return (
    <DropdownButton
      className="selectIcon"
      id="dropdown-basic-button"
      title={selected?.service?.name || "Select Service"}
    >
      {configs.map((cfg) => (
        <OverlayTrigger
          trigger="hover"
          placement="right"
          overlay={popoverContent(cfg.service?.description || "", cfg.price)}
        >
          <Dropdown.Item onClick={() => handleSelect(cfg)}>
            {cfg.service?.name}
          </Dropdown.Item>
        </OverlayTrigger>
      ))}
    </DropdownButton>
  );
};

export default DropdownPopover;
