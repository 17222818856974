import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Auth } from 'aws-amplify';
import spinnerSvc from './spinner-service';

const BASE_URL = process.env.REACT_APP_API_URL + "/api/v1";

// Function to get the bearer token from local storage
const getBearerToken = async (): Promise<string> => {
  try {
    const currentSession = await Auth.currentSession();
    return "Bearer " + currentSession.getIdToken().getJwtToken();
  } catch (err) {
    return "";
  }
};

// Create an instance of axios with custom configuration
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the bearer token
axiosInstance.interceptors.request.use(
  async (config: any) => {
    spinnerSvc.start();
    const token = await getBearerToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
    spinnerSvc.stop();
  }
);

// Add a response interceptor to handle responses
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    spinnerSvc.stop();
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error: AxiosError) => {
    spinnerSvc.stop();
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response) {
      // Handle different response errors here
      console.error(`Error: ${error.response.status} - ${error.response.data}`);
    } else {
      console.error(`Error: ${error.message}`);
    }
    return Promise.reject(error);
  }
);

// Function to make a GET request
export const get = async (url: string, params?: any): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/${url}`, {params});
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Function to make a POST request
export const post = async (url: string, data: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/${url}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Function to make a PUT request
export const put = async (url: string, data: any): Promise<any> => {
  try {
    const response = await axiosInstance.put(`/${url}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Function to make a DELETE request
export const remove = async (url: string, data: any): Promise<any> => {
  try {
    const response = await axiosInstance.delete(`/${url}`, { data });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
