import React from 'react'
import Header from '../../../Components/Header/Header'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const HeroServices = () => {
  return (
   <div className='secondaryHeader'>
      <div className="headerWrap">
        <Header />
      </div>
      
        <div className="card-hero">
      <Container>
          <div className="title">
            <span>Our Services</span>
            <h2>More Than Just a Wash—It's a Transformation.</h2>
            <Link to="#">Book Your Wash Now</Link>
          </div>
      </Container>
        </div>
    </div>
  )
}

export default HeroServices