import { DeleteVehicleApiPayload, VehicleApiPayload } from "../Types/vehicle.interface";
import { CustomerVehiclesAttributes } from "../features/vehicle.slice";
import { get, post, put } from "./api.service";

export const fetchSavedVehicles = async () => {
 
  try {
    const res = await get(`customer/vehicle`, {status: true});
    if (res?.status){
        let tempVehicles = [...res.result];
        tempVehicles = tempVehicles.filter((vehicle) => vehicle.status === true)
        return {error: null, data: tempVehicles};
    }
  } catch (error) {
    return {error: error, data: null};
  }
};

export const addVehicle = async (body: any) => {
  try {
    delete body.uuid
    const res = await post('customer/vehicle', body);
    if(res?.status){
      return {error: null, data: res.result};
    }
  } catch (error) {
    return {error: error, data: null};
  }
}

export const updateVehicle = async (body: CustomerVehiclesAttributes) => {
  try {
    const res = await put('customer/vehicle', body);
    if(res?.status){
      return {error: null, data: res.result};
    }
  } catch (error) {
    return {error: error, data: null};
  }
}

export const deleteVehicle = async (body: DeleteVehicleApiPayload) => {
  try {
    const res = await put('customer/vehicle', body);
    if(res?.status){
      return {error: null, isDeleted: true};
    }
  } catch (error) {
    return {error: error, isDeleted: false};
  }
}