import React, { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { fetchPromoCode, updateBooking } from "../../services/booking.service";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { settingSelector } from "../../features/setting.slice";
import { Booking } from "../../features/bookingSlice";
import { Promotion } from "../../features/promotion.slice";
interface CartItem {
  name: string;

  price: number;
}

interface CartSummaryProps {
  items?: CartItem[];
  discount: number;
  selectedItem: Booking | null;
  handleSelectItem: (data: any) => void;
}

// const CartSummary: React.FC<CartSummaryProps> = ({ items, discount, selectedItem }) => {
const CartSummary: React.FC<CartSummaryProps> = ({ selectedItem }) => {
  const dispatch = useDispatch();
  const [promoCode, setPromoCode] = useState<any>(null); // const { setIsBookingLoading } = useContext(BookingContext);
  const vat = useSelector(settingSelector);

  useEffect(() => {
    setPromoCode("");
  }, [selectedItem]);
  const promo = async () => {
    const payload = {
      auto_apply: false,
      code: promo,
      service_id: selectedItem?.booking_details[0]?.service_config?.service_id,
      location: [
        {
          lat: selectedItem?.booking_details[0]?.customer_address?.latitude,
          lng: selectedItem?.booking_details[0]?.customer_address?.longitude,
        },
      ],
    };
    const response = await fetchPromoCode(payload);
    const liveCodes = response.result.filter(
      (promo: Promotion) =>
        promo.status &&
        !promo.used &&
        promo.service_config_ids.includes(
          selectedItem?.booking_details[0].service_config?.service_id || ""
        )
    );
    if (liveCodes.length > 0) {
      if (liveCodes[0].is_applicable === false) {
        toast.dismiss();
        toast.error("Provided promotion code is invalid");
        return;
      }
    }

    if (liveCodes.length !== 0) {
      const createBookingRequestBody = {
        ...selectedItem,
        details: [
          {
            ...selectedItem?.booking_details[0],
            promotion_id: liveCodes[0]?.uuid,
            price:
              (selectedItem?.booking_details[0]?.price || 0) -
              (selectedItem?.booking_details[0]?.price || 0) *
                (liveCodes[0]?.discount_percentage / 100),
          },
        ],
      };
      const updateRes = await updateBooking(createBookingRequestBody);
      if (
        updateRes?.data?.message === null ||
        updateRes?.data?.message === "Ok"
      ) {
        setPromoCode("");
      } else {
        toast.dismiss();
        toast.error("Invalid Promo Code");
      }
    } else {
      toast.dismiss();
      toast.error("Invalid Promo Code");
    }
  };

  return (
    <div className="paymentWrapper">
      <div className="orderHeader mt-2 mb-3">
        <h3>Cart Summary</h3>
      </div>
      <div className="cardSummary">
        <h4>Promo Code</h4>
        <div className="formGroup">
          <div className="flex-1">
            <Form.Control
              placeholder="Enter code"
              onChange={(e) => setPromoCode(e.target.value)}
              value={promoCode}
              disabled={
                !selectedItem ||
                !!selectedItem?.booking_details[0]?.promotion_id
              }
            />
          </div>
          <button
            className="btn btnSecondary"
            onClick={promo}
            disabled={
              !selectedItem ||
              !!selectedItem?.booking_details[0]?.promotion_id ||
              promoCode === ""
            }
          >
            Apply
          </button>
        </div>
        <ul className="summaryDesc">
          {/* {items.map((item, index) => (
                        <li key={index}>
                            <div className="media-body">{item.name}</div>
                            <div className="price">AED {item.price}</div>
                        </li>
                    ))} */}

          <li>
            <div className="media-body">
              {selectedItem?.uuid
                ? selectedItem?.booking_details[0]?.service_config?.service
                    ?.name
                : "Service"}
            </div>
            <div className="price">
              AED {selectedItem?.booking_details[0]?.service_config?.price}
            </div>
          </li>
          <li>
            <div className="media-body">Discount</div>
            <div className="price">
              <del>
                AED{" "}
                {selectedItem?.booking_details[0]?.promotion_id
                  ? (selectedItem?.booking_details[0]?.service_config?.price ||
                      0) - selectedItem?.booking_details[0]?.price
                  : null}
              </del>{" "}
              {selectedItem?.booking_details[0]?.promotion_id
                ? 100 -
                  (selectedItem?.booking_details[0]?.price /
                    (selectedItem?.booking_details[0]?.service_config?.price ||
                      0)) *
                    100 +
                  `${"%"}`
                : 0}
              {/* {calculateDiscountPercentage()}% off */}
            </div>
          </li>
          <li>
            <div className="media-body">VAT</div>
            <div className="price">
              AED{" "}
              {(selectedItem?.booking_details[0]?.price || 0) > 0
                ? Number(
                    (selectedItem?.booking_details[0]?.price || 0) *
                      (Number(vat.vat) / 100)
                  ).toFixed(2)
                : 0}
              {/* {calculateDiscountPercentage()}% off */}
            </div>
          </li>
        </ul>
        <ul className="summaryTotal">
          <li>
            <div className="media-body">Total</div>
            <div className="price">
              AED{" "}
              {selectedItem?.booking_details[0]?.price
                ? Number(selectedItem?.booking_details[0]?.price) +
                  selectedItem?.booking_details[0]?.price *
                    (Number(vat.vat) / 100)
                : 0}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CartSummary;
