import Topbar from "./Topbar";
import { useState, useEffect } from "react";
import PrimaryMenu from "./PrimaryMenu";
import NavbarMain from "./NavbarMain";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { getApplicationSettings } from "../../services/applicationSetting.service";
import TagManager from "react-gtm-module";
import { setSetting, settingSelector } from "../../features/setting.slice";

export default function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state?.auth?.userData);
  const settings = useSelector(settingSelector);
  //

  const handleWhatsAppClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "WhatsApp",
      },
    });
    if (settings.whatsapp) {
      const number = settings.whatsapp?.replace(/\s/g, "");
      const whatsappUrl = `https://wa.me/${number}`;
      console.log(whatsappUrl);

      window.open(whatsappUrl, "_blank");
    }
  };

  const getApplicationConfigs = async () => {
    try {
      const res = await getApplicationSettings();
      dispatch(setSetting(res?.data?.result));
    } catch (error) {}
  };
  //
  useEffect(() => {
    getApplicationConfigs();
  }, []);
  //
  return (
    <>
      <header className="header">
        <NavbarMain />
        {/* <NavbarMain /> */}

        <div className="sticky-whatsaap">
          <a>
            <img
              src="/images/whatsaapButton.png"
              alt="logo"
              onClick={handleWhatsAppClick}
            />
          </a>
        </div>
      </header>
    </>
  );
}
