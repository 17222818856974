import { Icon } from "@iconify/react";
import { useState, useCallback, useEffect } from "react";
import {
  Modal,
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";

import { uploadImage } from "../../../services/user.service";
import { toast } from "react-hot-toast";
function EditProfile(props: any) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: props.phoneNumber,
    imagePath: null,
  });

  const handleInputChange = (event: any) => {
    setFormData((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const handleImageUpload = async (e: any) => {
    if (e.target.files?.length === 0) {
      toast.dismiss();
      toast.error("You must upload an image");
      return;
    }
    try {
      const res: any = await uploadImage(e.target.files[0]);
      if (res.data) {
        setFormData((prev) => {
          return { ...prev, imagePath: res.data };
        });
      }
    } catch (error) { }
  };

  const handleEdit = () => {
    props.onSubmission(formData);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="customModal profileModal"
    >
      <Modal.Body>
        <Container>
          <h3 className="title">Set your Profile</h3>

          <div className="cardProfile bg-transparent shadow-none">
            <div className="text-center mb-4">
              <FormControl
                type="file"
                id="file"
                hidden
                className="uploadFile"
                onChange={handleImageUpload}
              />
              <label htmlFor="file">
                <div className="placeholderImg">
                  {formData.imagePath && (
                    <img src={formData.imagePath} alt="" className="imgFluid" />
                  )}
                  {!formData.imagePath && (
                    <div className="p-2">
                      <img src="/images/gallery-add.svg" alt="" />
                      <span>Upload your photo</span>
                    </div>
                  )}
                </div>
              </label>
            </div>
            <Row>
              <Col sm={6}>
                <FormGroup className="form-group">
                  <FormLabel>First name</FormLabel>
                  <FormControl
                    type="text"
                    placeholder="First name"
                    name="firstName"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup className="form-group">
                  <FormLabel>Last name</FormLabel>
                  <FormControl
                    type="text"
                    placeholder="Last name"
                    name="lastName"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup className="form-group">
                  <FormLabel>Email</FormLabel>
                  <FormControl
                    type="email"
                    placeholder="email"
                    name="email"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup className="form-group">
                  <FormLabel>Phone number</FormLabel>
                  <FormControl
                    type="text"
                    placeholder="Phone number"
                    name="phoneNumber"
                    // onChange={handleInputChange}
                    value={props.phoneNumber}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="formButton">
              <Button className="btnSecondary btnIcon" onClick={handleEdit}>
                SAVE
                <i>
                  <Icon icon="ep:arrow-right" />
                </i>
              </Button>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default EditProfile;
