import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Badge, Dropdown, Form, Offcanvas } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {
  deleteBooking,
  duplicateBooking,
} from "../../services/booking.service";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../../store";
import { fetchDayId, fetchSlots } from "../../services/booking.service";

import DatePicker from "react-datepicker";
import TimePicker from "../widgets/TimePicker";
import LoadingIndicator from "../loader/loader";
import RemoveModal from "../checkout/RemoveModal";
import { Booking } from "../../features/bookingSlice";

interface Record {
  id: number;
  service: {
    title: string;
    description: string;
  };
  location: string;
  amount: string;
  date: string;
  time: string;
  status: string;
}
interface DriverInfo {
  end_original: string;
  start_time: string;
  uuid: string;
}

function HistoryRecord({
  records,
  sortDescending,
  setSortDescending,
  setSelectedBooking,
  handleShowRemoveModal,
  setIsDeleteSelected,
  selectedRecords,
  setSelectedRecords,
  isDeleteSelected,
  selectedRecordsId,
  setSelectedRecordsId,
}: {
  records: Booking[];
  sortDescending: boolean;
  setSortDescending: any;
  setSelectedBooking: any;
  handleShowRemoveModal: any;
  setIsDeleteSelected: any;
  selectedRecords: any;
  setSelectedRecords: any;
  isDeleteSelected: boolean;
  selectedRecordsId: any;
  setSelectedRecordsId: any;
}) {
  const getStatusBadgeVariant = (status: string) => {
    switch (status) {
      case "Accepted":
        return "primaryLight";
      case "Declined":
        return "dangerLight";
      case "Completed":
        return "successLight";
      default:
        return "secondary";
    }
  };
  const [startDate, setStartDate] = useState<Date>();
  const [availableDriverSlots, setAvailableDriverSlots] = useState([]);
  const [driverInfo, setDriverInfo] = useState<DriverInfo[]>([]);
  const [dayId, setDayId] = useState<string>();
  const [orderToDuplicate, setOrderToDuplicate] = useState<any>();
  const [show, setShow] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  // const [selectedRecordsId, setSelectedRecordsId] = useState<string[]>([]);
  // const [selectedBooking, setSelectedBooking] = useState<any>()

  const isAllSelected = selectedRecords.length === records.length;
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  //
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeleteSelected(!isDeleteSelected);

    setSelectedRecords(
      event.target.checked ? records.map((record) => record) : []
    );

    setSelectedRecordsId(
      event.target.checked
        ? records.map((record) => record?.booking_details[0].uuid)
        : []
    );
  };

  const handleRecordSelectChange = (
    record: Booking,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // setIsDeleteSelected(!isDeleteSelected);

    setSelectedRecords((prevSelectedRecords: Booking[]) =>
      event.target.checked
        ? [...prevSelectedRecords, record]
        : prevSelectedRecords.filter(
            (rec: Booking) =>
              rec?.booking_details[0].uuid !== record?.booking_details[0]?.uuid
          )
    );
    setSelectedRecordsId((prevSelectedRecordIds: any) =>
      event.target.checked
        ? [...prevSelectedRecordIds, record?.booking_details[0]?.uuid]
        : prevSelectedRecordIds.filter(
            (id: any) => id !== record?.booking_details[0]?.uuid
          )
    );
  };

  const handleDuplicateService = async () => {
    const payload = {
      old_uuid: orderToDuplicate?.bookingDetailsId,
      slot_selected_at: `${orderToDuplicate?.reservationDateForAPI}T00:00:00.000Z`,
      start_time: startTime,
      end_time: endTime,
      booking_at: `${orderToDuplicate?.reservationDateForAPI}T00:00:00.000Z`,
      day_id: orderToDuplicate?.dayId,
    };

    const res = await duplicateBooking(payload);

    if (!res.status) {
      toast.error(res?.result?.message);
    }
  };

  const handleDate = async (date: Date) => {
    setSelectedTime("");

    const hour = date.getHours();
    const minute = date.getMinutes();
    const monthDate = date.getDate();
    const day = daysOfWeek[date.getDay()];
    const month = date.getMonth() + 1; // Months start from 0
    const year = date.getFullYear();
  };
  const handleDriverInfo = (startTime: string) => {
    const data = driverInfo?.filter((info: any) => {
      return startTime === info?.start_time;
    });

    setStartTime(data[0]?.start_time);
    setEndTime(data[0]?.end_original);

    // dispatch(setStartTime(data[0]?.start_time));
    // dispatch(setEndTime(data[0]?.end_original));
    // dispatch(setDriverID(data[0]?.uuid));
    //
  };

  const handleTimeSelect = (time: any) => {
    setSelectedTime(time);

    handleDriverInfo(time);
  };

  const handleDropDown = (event: any, record: Booking) => {
    if (event === "delete") {
      if (record?.booking_details[0]?.booking_status_id === 6) {
        // handleDeleteService(record?.bookingDetailsId);
        setSelectedBooking(record);
        handleShowRemoveModal();
      } else {
        toast.error(
          "Incomplete order! Can't delete booking from history. To cancel, please refer to the app."
        );
      }
    } else if (event === "duplicate") {
      handleShow();
      // handleDuplicateService(record);
    }
  };
  //
  function formatDate(inputDate: string) {
    if (inputDate) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const [year, month, day] = inputDate?.split("-");
      const monthIndex = parseInt(month) - 1; // Adjust for 0-based index

      const formattedDate = `${day}-${months[monthIndex]}-${year}`;
      return formattedDate;
    }
  }

  return (
    <>
      <div className="d-none d-md-block">
        {records.length === 0 && (
          <div className="text-center  noHistoryImg">
            <img src="/images/noDataFound.svg" alt="" className="img-fluid" />
          </div>
        )}
        {records.length > 0 && (
          <Table hover>
            <thead>
              <tr>
                <th>
                  <Form.Check id="checkAll" className="customCheck">
                    <Form.Check.Input
                      className="checkbox"
                      type="checkbox"
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                      hidden
                    />
                    <Form.Check.Label htmlFor="checkAll">
                      <span></span>
                    </Form.Check.Label>
                  </Form.Check>
                </th>
                <th>
                  ID{" "}
                  <i
                    onClick={() => {
                      setSortDescending(!sortDescending);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {sortDescending ? (
                      <Icon icon="la:long-arrow-alt-down" />
                    ) : (
                      <Icon icon="la:long-arrow-alt-up" />
                    )}
                  </i>
                </th>
                <th>Service</th>
                <th>Location</th>
                <th>Amount</th>
                <th>Date/Time</th>
                <th>Status</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              <>
                {records?.map((record) => {
                  const detail = record.booking_details[0];
                  return (
                    <tr
                      key={detail?.uuid}
                      className={
                        selectedRecordsId.includes(detail?.uuid) ? "active" : ""
                      }
                    >
                      <td>
                        <Form.Check
                          id={`r${detail?.uuid}`}
                          className="customCheck"
                        >
                          <Form.Check.Input
                            className="checkbox"
                            type="checkbox"
                            hidden
                            checked={selectedRecordsId.includes(detail?.uuid)}
                            onChange={(event) => {
                              handleRecordSelectChange(record, event);
                            }}
                          />
                          <Form.Check.Label htmlFor={`r${detail.uuid}`}>
                            <span></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </td>
                      <td>{record.id}</td>
                      <td>
                        <div className="service">
                          <h4>{detail?.service_config?.service?.name}</h4>
                          {/* <p>{"hello"}</p> */}
                        </div>
                      </td>
                      <td>
                        <div className="location">
                          {detail?.customer_address?.area}
                        </div>
                      </td>
                      <td>
                        <div className="price">{`AED ${detail?.price}`}</div>
                      </td>
                      <td>
                        <div className="date">
                          {formatDate(detail?.bookingAt?.split("T")[0])}
                        </div>
                        <div className="time">{detail?.start_time}</div>
                      </td>
                      <td>
                        <Badge
                          bg={getStatusBadgeVariant(
                            detail?.booking_status.name
                          )}
                        >
                          {detail?.booking_status?.name}
                        </Badge>
                      </td>
                      <td className="text-end">
                        <Dropdown
                          onSelect={(event) => {
                            handleDropDown(event, record);
                          }}
                        >
                          <Dropdown.Toggle
                            variant="ellipsesCta"
                            id={`dropdown-${record.id}`}
                          >
                            <Icon icon="tabler:dots" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item
                              href="#"
                              name="delete"
                              eventKey="delete"
                            >
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              name="duplicate"
                              eventKey="duplicate"
                              onClick={() => {
                                setOrderToDuplicate(record);
                              }}
                            >
                              Duplicate
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}

                <Offcanvas
                  className="canvasRight"
                  show={show}
                  onHide={handleClose}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Duplicate Service</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="timeDate">
                      <DatePicker
                        inline
                        dateFormat="MMMM d, yyyy"
                        selected={startDate}
                        onChange={handleDate}
                        minDate={new Date()}
                      />
                      <TimePicker
                        selectedDate={startDate}
                        timeList={availableDriverSlots}
                        selectedTime={selectedTime}
                        onTimeSelect={handleTimeSelect}
                      />
                    </div>
                    <div className="mt-4 text-end">
                      <button
                        className="btn btn-primary btnPrimary py-2 px-4"
                        onClick={handleDuplicateService}
                      >
                        <span>Duplicate</span>
                      </button>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </>
            </tbody>
          </Table>
        )}
      </div>

      <div className="d-md-none d-block">
        {records?.map((record) => {
          const detail = record.booking_details[0];
          return (
            <div
              key={detail.uuid}
              className={`cardHistoryRecord
             ${selectedRecordsId.includes(detail.uuid) ? "active" : ""}
                `}
            >
              <div className="headerHistory">
                <Form.Check id={`r${detail.uuid}`} className="customCheck">
                  <Form.Check.Input
                    className="checkbox"
                    type="checkbox"
                    hidden
                    checked={selectedRecordsId.includes(detail.uuid)}
                    onChange={(event) => {
                      handleRecordSelectChange(record, event);
                    }}
                  />
                  <Form.Check.Label htmlFor={`r${detail.uuid}`}>
                    <span></span>
                  </Form.Check.Label>
                </Form.Check>

                <div className="text-end">
                  <Dropdown
                    onSelect={(event) => {
                      handleDropDown(event, record);
                    }}
                  >
                    <Dropdown.Toggle
                      variant="ellipsesCta"
                      id={`dropdown-${record.id}`}
                    >
                      <Icon icon="tabler:dots" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item href="#" name="delete" eventKey="delete">
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        name="duplicate"
                        eventKey="duplicate"
                        onClick={() => {
                          setOrderToDuplicate(record);
                        }}
                      >
                        Duplicate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="record">
                <div className="recordId">
                  <span>ID</span> {record?.id}
                </div>
                <div>
                  <Badge
                    bg={getStatusBadgeVariant(
                      detail.booking_status_id?.toString()
                    )}
                  >
                    {detail.booking_status?.name}
                  </Badge>
                </div>
              </div>
              <div>
                <div className="service">
                  <h4>
                    <span>Name</span>
                    {detail?.service_config?.service?.name}
                  </h4>
                  <div className="price">{`AED ${detail?.price}`}</div>
                </div>
              </div>
              <div className="cardFooterHistory">
                <div className="locationHistory">
                  <span>
                    <Icon icon="carbon:location" />
                  </span>
                  <div className="location">
                    {detail?.customer_address?.area}
                  </div>
                </div>
                <div>
                  <div className="date">
                    {formatDate(detail?.bookingAt?.split("T")[0])}
                  </div>
                  <div className="time">{detail?.start_time}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default HistoryRecord;
